import React, { useEffect, useState } from 'react';
import { ButtonWithIcon, Header, MetaDecoratorHelmet, MoleculeAddToCalendarBtn, MoleculeStatus, ReadMore } from '../../Molecules';
import { Icon, Text } from '../../Atoms';
import { imagePath } from './../../Config/Config';
import { getShareText, onGetDay, onGetMonth, onGetTime } from '../../common';
import { OrganismMaps } from '../../Organisme';
import { scrollToTop } from '../../Redux/authActions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import {Helmet} from "react-helmet";


const TemplateEventDetails = (props) => {
    const { token, user } = useSelector((reduxState) => reduxState.auth);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(scrollToTop())
    }, [])

    const getTextButton = () => {
        if(props?.actuelResponse){
            return `EDIT MY REPLY(${props?.actuelResponse})`
        } else {
            return 'REPLY'
        }
    }
    const isMultiDay = (onGetDay(props.currentEvent?.start_date) === onGetDay(props.currentEvent?.end_date)) && (onGetMonth(props.currentEvent?.start_date) === onGetMonth(props.currentEvent?.end_date))
    return (
        <>
         <MetaDecoratorHelmet 
         title={"REEPLY"}
         description={props?.currentEvent?.description}
         imageUrl={imagePath + props.currentEvent?.imageName}
         url={"https://reeply.io/eventDetails/" + props.currentEvent?.id}
         imageAlt={"the image of the events "}
    />
        <div className='ds-w-100 ds-h-100 relative'>
            <div className='relative'>
                <div className='ds-w-100 ds-pl-14 ds-pr-20 absolute justify-between'>
                    <Header
                        shadowBox={true}
                        type={"type2"}
                        onClickEdit={() => props.onClickEdit("/event")}
                        onClickShare={() => props.onClickShare("/contacts")}
                        onClickBack={() => props.onClickBackEvent()}
                        withShadow
                        edit={token ? user?.id === props.currentEvent?.user?.id : false}
                    />
                </div>
                {props.currentEvent?.imageName
                    && <img src={imagePath + props.currentEvent?.imageName} className="ds-w-100 z-index-3 relative auto-img" />
                }
            </div>

            <div className='ds-pr-20 ds-pb-50 ds-pl-14 ds-mt-20 ds-w-100'>
                <Text type={"type-0"} className="word-wrap">{props.currentEvent?.label}</Text>
                <MoleculeStatus className={"status ds-mt-10"} status={props.currentEvent?.statusText} />
                {isMultiDay ? < div className='ds-w-100 ds-hp-93 flex align-center justify-between ds-mt-20'>
                    <div className='ds-w-30 ds-pr-12'>
                        <div className='flex-col center date-box ds-hp-93 ds-w-100'>
                            <Text type='type-14'>{onGetMonth(props.currentEvent?.start_date)}</Text>
                            <Text type="first-text type-20">{onGetDay(props.currentEvent?.start_date)}</Text>
                        </div>
                    </div>
                    <div className='date-box ds-hp-93 flex center ds-w-70'>
                        <Text type='type-21'>{onGetTime(props.currentEvent?.start_date)}</Text>
                        <Icon icon="Arrow---Up-2" size={30} className="ds-ml-20 ds-mr-20" />
                        <Text type="type-21">{onGetTime(props.currentEvent?.end_date)}</Text>
                    </div>
                </div> :
                    <>
                        < div className='ds-w-100 ds-hp-93 flex align-center justify-between ds-mt-20'>
                            <div className='ds-w-50 ds-pr-12'>
                                <div className='flex-col center date-box ds-hp-93 ds-w-100'>
                                    <Text type='type-14'>{onGetMonth(props.currentEvent?.start_date)}</Text>
                                    <Text type="first-text type-20">{onGetDay(props.currentEvent?.start_date)}</Text>
                                </div>
                            </div>
                            <div className='date-box ds-hp-93 flex center ds-w-50'>
                                <Text type='type-21'>{onGetTime(props.currentEvent?.start_date)}</Text>
                            </div>
                        </div>
                        < div className='ds-w-100 ds-hp-93 flex align-center justify-between ds-mt-20'>
                            <div className='ds-w-50 ds-pr-12'>
                                <div className='flex-col center date-box ds-hp-93 ds-w-100'>
                                    <Text type='type-14'>{onGetMonth(props.currentEvent?.end_date)}</Text>
                                    <Text type="first-text type-20">{onGetDay(props.currentEvent?.end_date)}</Text>
                                </div>
                            </div>
                            <div className='date-box ds-hp-93 flex center ds-w-50'>
                                <Text type="type-21">{onGetTime(props.currentEvent?.end_date)}</Text>
                            </div>
                        </div>
                    </>
                }
                <OrganismMaps
                    address={props?.currentEvent?.location}
                />
                <ReadMore
                    text={props.currentEvent?.description}
                />
                {token ? <ButtonWithIcon
                    text={"GUEST LIST"}
                    className="type-2"
                    icon={"user"}
                    onClick={props.onShowGuestList}
                    disabled={token ? false : true}
                /> : null}
                <div className='ds-pt-10'>
                    <ButtonWithIcon text="ADD TO MY AGENDA"
                        className="type-3"
                        icon="Plus-1"
                        iconSize={28}
                        iconClassName="third-icon"
                        onClick={props.addAgenda}
                    />
                </div>
                <div className={`ds-pt-10`} >
                    <ButtonWithIcon text={getShareText()}
                        className="type-3"
                        icon="link"
                        iconSize={33}
                        iconClassName="first-icons"
                        onClick={props.onShare}
                        isShare
                    />
                </div>
                {((props?.currentEvent?.user?.id !== props.user?.id) && (props?.currentEvent?.user?.id !== props?.locationState?.userId?.id)) && <ButtonWithIcon
                    text={getTextButton()}
                    textType={"type-black"}
                    className="ds-mt-15"
                    onClick={() => props.onOpenReplyPopup()}
                />}
            </div>
        </div >
        <div className="application">
      
    </div>
    </>
    )
}

export default TemplateEventDetails