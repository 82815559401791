import { apiGet, apiPost } from "../standardApi";

export async function apiGetEvents() {
    return apiGet('event')
}

export async function apiGetEvent(eventId, subscriptionToken) {
    if(subscriptionToken) return apiGet('event/' + eventId + '?subscriptionToken=' + subscriptionToken)
    return apiGet('event/' + eventId )
}

const defineFormDataBody = (body) => {
    const formData = new FormData();
    formData.append("label", body.name);
    formData.append("location", body.location);
    formData.append("start_date", body.startingDate);
    formData.append("end_date", body.endingDate);
    formData.append("description", body.description);
    formData.append("image_file", body.image);
    formData.append("is_allowed_relative_add", body.allowRelative);
    return formData
}
const defineFormDataUnloggedEvent = (body) => {
    const formData = new FormData();
    formData.append("firstname", body.firstname);
    formData.append("lastname", body.lastname);
    formData.append("email", body.email);
    formData.append("password", body.password);
    formData.append("label", body.label);
    formData.append("location", body.location);
    formData.append("start_date", body.start_date);
    formData.append("end_date", body.end_date);
    formData.append("description", body.description);
    formData.append("image_file", body.image_file);
    formData.append("is_allowed_relative_add", body.is_allowed_relative_add);
    return formData
}

export async function apiCreateEvent(body) {
    const formData = defineFormDataBody(body)
    return apiPost("event", formData, "form-data")
}
export async function apiUpdateEvent(body) {
    const formData = defineFormDataBody(body)
    return apiPost('event-update/' + body.id, formData, "form-data")
}
export async function apiGetSubscription(eventId) {
    return apiGet('subscription/' + eventId)
}
export async function apiGetSubscriptionStatus(eventId, status) {
    return apiGet('subscription/' + eventId + '?status=' + status)
}
export async function apiGetMyEvents() {
    return apiGet('my-events');
}
export async function apiGetMyEventsFiltred(filtered) {
    return apiGet('my-events?sort_date=' + filtered);
}
export async function apiGetMyResponse(body) {
    return apiPost('reply', body, "application/json");
}
export async function apiReplyToEvent(body) {
    return apiPost('reply-guest', body, "application/json", true);

}
export async function apiUpdateReplyToEvent(body) {
    return apiPost('reply-guest-update', body, "application/json", true);
}
export async function apiAddEventUnlogged(body) {
    const formData = defineFormDataUnloggedEvent(body)
    return apiPost('event-guest', formData, "form-data", true);
}

export async function apiExportEvent(eventId) {
    return apiGet('event-export/' + eventId, "text/html")
}
export async function apiEditReplay(body) {
    return apiPost('editReply', body,);
}
export async function apiDeleteReplay(body) {
    return apiPost('deleteReply', body,);
}