import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { validateEmail } from "./../../Validation/index";
import { GenericOrganismModal } from "../../Organisme";
import { TemplateSignup } from "../../Templates";
import { defineState } from "../../common";
import { apiSignup } from "../../Api/SignUp/SignUp";
import { useDispatch, useSelector } from "react-redux";
import { error } from "../../common/validationMessage";
import { apiReplyToEvent } from "../../Api/Events/events";
import { removeLastEventToReply, resetEvent, setEvent, setEventToReply } from "../../Redux/EventActions/EventActions";

const PageSignup = () => {
  const [bool, setBool] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { event } = useSelector((reduxState) => reduxState.event);
  const [openPopSignup, setOpenPopSignup] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isShow, setIsShow] = useState(false);
  const [idEvent, setIdEvent] = useState(null);
  const [idUser, setIdUser] = useState(null);
  const { state: eventState } = location;
  const [locationState, setLocationState] = useState(eventState);
  const [state, setState] = useState({
    firstName: { ...defineState("") },
    lastName: { ...defineState("") },
    email: { ...defineState("") },
    password: { ...defineState("") },
    repeatPassword: { ...defineState("") },
    phone: { ...defineState("") }
  });
  const onBlur = (key, value) => {
    const aux = { ...state };
    const isEmpty = value === "";
    if (key === "email") {
      const isMail = validateEmail(value);
      if (!isMail) {
        aux.email.isInvalid = true;
        aux.email.errormsg = error.checkMail;
      } else {
        aux.email.isInvalid = false;
      }
    }
    if (key === "repeatPassword" || (key === "password" && state.repeatPassword.value)) {
      aux.password.isInvalid = false;
      aux.repeatPassword.isInvalid = false;
      if (aux.password.value !== aux.repeatPassword.value) {
        aux.repeatPassword.isInvalid = true;
        aux.repeatPassword.errormsg = error.checkPassword;
      }
    }
    if (key === "firstName") {
      aux.firstName.isInvalid = isEmpty;
    }
    if (key === "lastName") {
      aux.lastName.isInvalid = isEmpty;
    }
    setState(aux);
  }
  const onChange = (key, value) => {
    const aux = { ...state };
    aux[key].value = value;
    aux[key].isInvalid = false;
    setState(aux);
  }
  const onClosePopupSignup = () => {
    if (locationState?.replyEvent) {
      setOpenPopSignup(false);
      navigate(`/eventDetails/${idEvent}`);
    } else if (locationState?.event && locationState?.fromEvent) {
      setOpenPopSignup(false);
      setIsShow(false)
      navigate(`/eventDetails/${idEvent}`, {
        state: {
          eventCreate: true,
          eventId: idEvent,
          eventSent: locationState?.event,
          userId: idUser
        }
      });
    } else {
      setOpenPopSignup(false);
      navigate("/signin");
    }
  };

  const onReplyEvent = async () => {
    const msg = "email: This value is already used.";
    const msgreply = "You are already subscribed !";
    const response = await apiReplyToEvent(
      JSON.stringify({
        firstname: state.firstName.value,
        lastname: state.lastName.value,
        email: state.email.value,
        event_id: locationState?.eventId,
        status: locationState?.replyEvent
      })
    );
    if (response.statusCode === 201) {
      dispatch(removeLastEventToReply());
      navigate(`/eventDetails/${locationState?.eventId}`, {
        replace: true,
        state: {
          replyStatus: locationState?.replyEvent,
          uniqueToken: response?.data?.uniqueToken
        }
      });
    } else {
      let errors = {};
      if (response.data.message.includes(msg)) {
        errors.email = error.acountExist;
      }
      if (response?.data?.message.includes(msgreply)) {
        errors.email = error.alreadySubscribed;
      }
      if (response.data?.message === "You are already subscribed !") {
        errors.email = "You are already subscribed !"
      }
      onChangeErrorMsg(errors);
    }
  }

  const renderError = () => {
    let errors = {};
    errors.firstName = !state.firstName.value ? error.emptyFirstName : null;
    errors.lastName = !state.lastName.value ? error.emptyLastName : null;
    errors.email = !state.email.value ? error.emptyMail : null;
    // errors.password = !state.password.value ? error.emptyPassword : null;
    errors.repeatPassword = !state.repeatPassword.value ? error.emptyPassword : null;
    // errors.phone = !state.phone.value ? error.emptyPhone : null;
    errors.repeatPassword = state.repeatPassword.value !== state.password.value ? error.checkPassword : null;

    onChangeErrorMsg(errors);
  }

  const onCheckSubmit = () => {
    if (locationState?.eventId && locationState?.replyEvent !== undefined && locationState.replyEvent !== null) {
      return !state.firstName.isInvalid &&
        !state.lastName.isInvalid &&
        !state.email.isInvalid &&
        state.firstName.value !== "" &&
        !state.lastName.value !== "" &&
        !state.email.value !== ""
    } else {
      return !state.firstName.isInvalid &&
        !state.lastName.isInvalid &&
        !state.email.isInvalid &&
        !state.password.isInvalid &&
        !state.repeatPassword.isInvalid &&
        !state.phone.isInvalid &&
        state.firstName.value !== "" &&
        state.lastName.value !== "" &&
        state.email.value !== "" &&
        state.password.value !== "" &&
        state.repeatPassword.value !== ""
      //  &&state.phone.value !== ""
    }
  }
  const onSubmit = async () => {
    setIsLoading(true)
    const msg = "email: This value is already used.";
    if (locationState?.eventId && locationState?.replyEvent !== undefined && locationState.replyEvent !== null) {
      if (onCheckSubmit()) {
        onReplyEvent()
      } else {
        let errors = {};
        errors.firstName = error.emptyFirstName;
        errors.lastName = error.emptyLastName;
        errors.email = error.emptyMail;
        onChangeErrorMsg(errors);
      }
    } else {
      if (onCheckSubmit()) {
        if (state.password.value !== state.repeatPassword.value) {
          let errors = {};
          errors.repeatPassword = !state.repeatPassword.value ? error.checkPassword : null;
          setTimeout(() => {
            onChangeErrorMsg(errors);
          }, 500);
        } else {
          const formData = onSignUpFormData();
          const response = await apiSignup(formData);
          if (response.statusCode === 201) {
            dispatch(resetEvent());
            setOpenPopSignup(true);
            setIdEvent(
              response?.data?.events[response?.data?.events?.length - 1].id
            );
            if (locationState?.fromEvent && locationState?.event) {
              setIsShow(true)
              setLocationState(null);
              setIdUser(response?.data?.user);
            }
          } else {
            let errors = {};
            if (
              response?.detail?.includes(msg) ||
              response?.data?.message?.includes(msg) ||
              response?.data?.message === msg
            ) {
              errors.email = error.acountExist;
            }
            if (errors?.email) {
              onChangeErrorMsg(errors);
            } else {
              renderError()
            }
          }
        }
      } else {
        renderError({})
      }
    }
    setIsLoading(false)
  };
  const onChangeErrorMsg = ({
    email = null,
    password = null,
    repeatPassword = null,
    phone = null,
    firstName = null,
    lastName = null
  }) => {
    setState({
      ...state,
      firstName: {
        ...state.firstName,
        isInvalid: firstName ? true : false,
        errormsg: firstName
      },
      lastName: {
        ...state.lastName,
        isInvalid: lastName ? true : false,
        errormsg: lastName
      },
      phone: {
        ...state.phone,
        isInvalid: phone ? true : false,
        errormsg: phone
      },
      repeatPassword: {
        ...state.repeatPassword,
        isInvalid: repeatPassword ? true : false,
        errormsg: repeatPassword
      },
      password: {
        ...state.password,
        isInvalid: password ? true : false,
        errormsg: password
      },
      email: {
        ...state.email,
        isInvalid: email ? true : email ? true : false,
        errormsg: email
      }
    });
  };
  const onSignUpFormData = () => {
    let formData = new FormData();
    formData.append("firstname", state.firstName.value);
    formData.append("lastname", state.lastName.value);
    formData.append("email", state.email.value);
    formData.append("password", state.password.value);
    formData.append("repeatedPassword", state.repeatPassword.value);
    formData.append("phone", state.phone.value);
    for (let index = 0; index < event?.length; index++) {
      formData.append(`tmpEvents[${index}][label]`, event[index]?.name);
      formData.append(`tmpEvents[${index}][location]`, event[index]?.location);
      formData.append(
        `tmpEvents[${index}][start_date]`,
        event[index]?.startingDate
      );
      formData.append(
        `tmpEvents[${index}][end_date]`,
        event[index]?.startingDate
      );
      formData.append(
        `tmpEvents[${index}][description]`,
        event[index]?.description
      );
      formData.append(`tmpEvents[${index}][image_file]`, event[index]?.image);
      formData.append(
        `tmpEvents[${index}][is_allowed_relative_add]`,
        event[index]?.allowRelative
      );
    }
    return formData;
  };
  useEffect(() => {
    setBool(true);
  }, []);
  useEffect(() => {
    if (bool && locationState?.fromEvent) {
      return () => {
        if (locationState) {
          dispatch(setEvent(locationState?.event));
        }
      };
    }
  }, [locationState]);
  return (
    <>
      <TemplateSignup
        isShow={isShow}
        state={state}
        onChange={onChange}
        onBlur={onBlur}
        onSubmit={onSubmit}
        onClickSignIn={() => navigate("/signin")}
        locationState={locationState}
        isLoading={isLoading}
      />
      <GenericOrganismModal
        showModal={openPopSignup}
        onCloseModal={() => onClosePopupSignup()}
        eventCreated={false}
        text={
          "We just sent you an email with a link to activate your account. "
        }
        title1={""}
        title={"Check your inbox"}
        btnText={"GOT IT"}
        moldeculeClassName={"modale-resetpassword"}
        resetPassword={false}
        iconDone={true}
        onClickFirstBtn={() => onClosePopupSignup()}
        showAddRelative
      />
    </>
  );
};

export default PageSignup;
