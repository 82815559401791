import React, { useEffect, useState } from 'react';
import { defineState } from '../../common';
import { TemplateResetPassword } from '../../Templates';
import { error } from './../../common/validationMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { apiCheckPassword, apiResetPassword } from '../../Api/Login/Login'
import { useDispatch } from 'react-redux';
import { resetToken } from '../../Redux/authActions/authActions';

const PageResetPassword = () => {
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [isValidToken, setIsValidToken] = useState(null)
    const [state, setState] = useState({
        password: { ...defineState('') },
        repeatPassword: { ...defineState('') },
    })
    const onBlur = (key, value) => {
        const aux = { ...state }
        aux[key].value = value
        aux.password.isInvalid = false;
        aux.repeatPassword.isInvalid = false;
        if (aux.password.value && aux.repeatPassword.value && aux.password.value !== aux.repeatPassword.value) {
            aux.password.isInvalid = true;
            aux.repeatPassword.isInvalid = true;
            aux.password.errormsg = error.checkPassword;
            aux.repeatPassword.errormsg = error.checkPassword;
        }
        setState(aux)
    }
    const onChange = (key, value) => {
        const aux = { ...state }
        aux[key].value = value
        setState(aux)
    }
    const onSubmit = async () => {
        setIsLoading(true)
        if (!state.repeatPassword.isInvalid && !state.password.isInvalid && state.repeatPassword.value !== '' && state.password.value !== "" && state.password.value === state.repeatPassword.value) {
            const response = await apiResetPassword(JSON.stringify({
                password: state.password.value,
                repeatedPassword: state.repeatPassword.value,
                reset_password_token: params.token
            }))
            if (response?.statusCode === 200) {
                navigate('/signin')
            } else {
                let errors = {}
                errors.password = error.checkPassword
                errors.repeatPassword = error.checkPassword
                onChangeErrorMsg(errors)
            }
        } else {
            let errors = {}
            errors.password = error.emptyPassword
            errors.repeatPassword = error.emptyPassword
            onChangeErrorMsg(errors)
        }
        setIsLoading(false)
    }
    const onChangeErrorMsg = ({ password = null, repeatPassword = null }) => {
        setState({
            ...state,
            password: {
                ...state.password,
                isInvalid: password ? true : false,
                errormsg: password
            },
            repeatPassword: {
                ...state.repeatPassword,
                isInvalid: repeatPassword ? true : false,
                errormsg: repeatPassword
            }
        })
    }
    const onChceckPassword = async () => {
        const response = await apiCheckPassword(JSON.stringify({
            reset_password_token: params.token
        }))
        if (response.statusCode !== 200) {
            setIsValidToken(false)
        } else {
            setIsValidToken(true)

        }
    }
    const resetUserToken =()=> {
        dispatch(resetToken())
    }
    useEffect(() => {
        resetUserToken()
        onChceckPassword()
    }, [])
    return (
        <TemplateResetPassword
            state={state}
            onChange={onChange}
            onBlur={onBlur}
            onSubmit={onSubmit}
            isValidToken={isValidToken}
            onRedirect={() => navigate('/signin', { replace: true })}
            isLoading={isLoading}
        />
    )
}

export default PageResetPassword