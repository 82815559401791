export const error = {
    emptyFirstName: "Please enter First name",
    emptyLastName: "Please enter Last name",
    emptyPhone: "Please enter phone",
    emptyMail: "Please enter mail",
    checkMail: "Check your mail",
    checkPhone: "Check your phone",
    emptyPassword: "Please enter password",
    checkPassword: "Check your password!",
    noContact: "There is no contact yet",
    noGuest: "Nobody has replied for the moment. Plase come back later :)",
    emptyImage: "Please add image",
    emptyEventName: "please enter an event name",
    emptyLocation: "please enter a location",
    emptyDate: "please enter date",
    endingDateGreater: "please enter a valid date",
    emptyDescription: "please enter a description",
    acountExist: "This account already exists",
    alreadySubscribed:"This account is already subscribed !"
}