import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { Icon, Input, Text } from '../../Atoms';
import trash from "../../Assets/Icons/trash.svg"
import ClickAwayListener from "react-click-away-listener";
const Contact = (props) => {
  const [isShow, setIsShow] = useState(false)
  const renderIcon = () => {
    if (props.showAction) {
      if (props.deleteContact) {
        return <img src={trash}
          className={`ds-wp-24 ds-text-white ds-hp-24 pointer ${props.iconClassName}`}
          onClick={props.onClickAdd} />
      }
      return <div className='relative'>
        <Icon icon={"EDIT"} size={30} onClick={() => setIsShow(true)} className={"pointer " + props.iconClassName} />
        {isShow && <ClickAwayListener onClickAway={() => setIsShow(false)}>
          <div className='absolute container-option-dropdown ds-p-15 flex flex-col justify-between'>
            <div className='flex align-center pointer' onClick={() => props.onClickAdd("edit")}>
              <Icon icon={"EDIT"} size={20} />
              <Text type="type-88" className={"ds-ml-18"}>Edit status</Text>
            </div>
            <div className='flex align-center pointer' onClick={() => props.onClickAdd("delete")}>
              <Icon icon={"EDIT"} size={20} />
              <Text type="type-88" className={"ds-ml-18"}>Delete guest</Text>
            </div>
          </div>
        </ClickAwayListener>
        }
      </div >
    }
    return null
  }
  return (
    <div className='relative ds-w-100'>
      <div className='flex align-center justify-between default-contact ds-w-100 ds-pt-13 ds-pb-13 ds-pl-15 ds-pr-15 ds-mb-10'>
        <div className='flex align-center justify-between'>
          {!props.isAdded && props.avatar === "" && <div className="profie-img border-radius-50 ds-mr-20 relative flex center" >
            <Text type={"upper-case"} className="absolute">{props.name}</Text>
          </div>}
          {props.isAdded && <Icon icon="turn" size={30} className="ds-ml-10 ds-mr-20" />}
          <Text type={"type-2"}>{props.pseudo}</Text>
        </div>
        {props.isCheckBox ?
          props.isSelected ? < Icon icon={"close-2"} size={30} onClick={props.onClickAdd} className={"ds-mr-15"} />
            : <div style={{ borderRadius: "4px" }} className={'ds-hp-20 ds-wp-20 ds-bg-white ds-mr-21 pointer' + props.iconClassName} onClick={props.onClickAdd}></div>
          :
          renderIcon()
        }
      </div>
      <div className='ds-w-97 ligne'></div>
    </div >
  )
}

export default Contact

Contact.defaultProps = {
  avatar: "",
  name: "",
  pseudo: "Jane Doe",
  onClick: null,
  iconClassName: "ds-mr-15",
  onClickAdd: () => console.log(),
  isAdded: false,
  deleteContact: false,
  className: "check-box",
  showAction: true,
};
Contact.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  pseudo: PropTypes.string,
  onClick: PropTypes.any,
  iconClassName: PropTypes.string,
  onClickAdd: PropTypes.func,
  isAdded: PropTypes.bool,
  deleteContact: PropTypes.bool,
  className: PropTypes.string,
  showAction: PropTypes.bool,
};