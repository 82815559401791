import { apiDelete, apiGet, apiPost } from "../standardApi";

export async function apiGetContacts() {
    return apiGet('contact')
}
export async function apiAddSubscription(body) {
    return apiPost('subscription', body)
}
export async function apiDeleteContact(id) {
    return apiDelete('remove-contact/' + id)
}