import React, { useRef, useEffect, useState } from "react";
import {
  ButtonWithIcon,
  Datepicker,
  Header,
  InputFile,
  MoleculeInput,
  MoleculesPlaceAutoComplete,
  SwitchInput,
} from "../../Molecules";
import { Text, TextArea } from "../../Atoms";
import { getComponentProps, render2Cercles } from "../../common";
import { useDispatch } from "react-redux";
import { scrollToTop } from "../../Redux/authActions/authActions";
const TemplateEvent = (props) => {
  const dispatch = useDispatch();
  const renderTitle = () => {
    if (props.id) {
      return "Edit event";
    }
    return "Create event";
  };
  const renderDescriptionClassName = () => {
    if (
      props.state.startingDate?.isInvalid ||
      props.state.endingDate?.isInvalid
    )
      return "ds-pt-20";
    return "ds-pt-4";
  };
  useEffect(() => {
    dispatch(scrollToTop());
  }, []);
  return (
    <div className="ds-w-100 ds-h-100 relative">
      {render2Cercles()}
      <div className="ds-pr-20 ds-pb-50 ds-pl-14 ds-w-100">
        <div className="cercle-greay absolute z-index-3"></div>
        <Header
          text={renderTitle()}
          edit={false}
          share={false}
          type="type2"
          className="Description"
          onClickBack={() => props.onClickBack()}
        />
        <div className="ds-mt-10 ds-w-100 z-index-100 ds-w-100">
          <MoleculeInput
            label={"Name of event"}
            onChange={(e) => props.onChange("name", e.target.value)}
            {...getComponentProps(props?.state?.name)}
          />
          <div className="ds-mt-20 z-index-100 ds-w-100">
            <MoleculesPlaceAutoComplete
              label={"Location"}
              onChange={(e) => props.onChange("location", e)}
              {...getComponentProps(props?.state?.location)}
            />
          </div>
          <div className="ds-mt-20 flex z-index-100 ds-w-100">
            <div className="ds-pr-6 ds-w-50">
              <Datepicker
                label={"Starting date"}
                className="date-picker"
                onChange={(e) => props.onChange("startingDate", e)}
                {...getComponentProps(props?.state?.startingDate)}
              />
            </div>
            <div className="ds-pl-6 ds-w-50">
              <Datepicker
                label={"Ending date"}
                className="date-picker z-index-100"
                onChange={(e) => props.onChange("endingDate", e)}
                {...getComponentProps(props?.state?.endingDate)}
              />
            </div>
          </div>
          <div className={renderDescriptionClassName()}>
            <TextArea
              label={"Description"}
              onChange={(e) => props.onChange("description", e.target.value)}
              {...getComponentProps(props?.state?.description)}
            />
          </div>
          <InputFile
            onChange={(e) => props.onChange("image", e.target.files[0])}
            text={"Upload image"}
            icon={"Download"}
            iconClassName={"upload-icon"}
            {...getComponentProps(props?.state?.image)}
          />
          <div className="ds-mt-20 flex align-center ds-w-100">
            <SwitchInput
              checked={props.state.allowRelative}
              onchangeHandle={props.onChnageSwitch}
            />
            <Text className={"ds-ml-21"}>Guest can also invite</Text>
          </div>
          <div className="ds-w-100 ds-pt-80">
            <ButtonWithIcon
              text={"DONE"}
              textType="type-black"
              onClick={() => props.onSubmitData()}
              isLoading={props.isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateEvent;
