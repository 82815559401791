import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Text } from '../../Atoms';
import { render2Cercles } from '../../common';
import { error } from '../../common/validationMessage';
import { imageUser } from '../../Config/Config';
import { ButtonWithIcon, Header } from '../../Molecules';
import { Contact } from '../../Organisme';
import { scrollToTop } from '../../Redux/authActions/authActions';

const TemplateContactList = (props) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(scrollToTop())
}, [])
  return (
    <div className='ds-w-100 ds-h-100 relative'>
      {render2Cercles()}
      <div className='ds-p-20 ds-w-100 ds-h-100 flex-col'>
        <Header
          type="type2"
          textType="type-7"
          text="Invite my contacts"
          edit={false}
          share={false}
          onClickBack={props.onClickBack}
        />

        {props?.contactsList?.length === 0 ? <Text type={"type-2"} className="ds-w-100 ds-h-100 flex center">{error.noContact}</Text> :
          <div className="overflow-y flex-gr-1 ds-w-100 ds-mt-30" id="style-1">
            <div className='center ds-w-100'>
              {props?.contactsList?.map((el, i) => {
                return (
                  <Contact
                    key={i}
                    name={el.firstname.slice(0, 1) + "" + el.lastname.slice(0, 1)}
                    avatar={el.imageName !== null ? imageUser + el.imageName : ""}
                    pseudo={el?.firstname + " " + el?.lastname}
                    onClickAdd={() => props.onAddContact(i)}
                    isCheckBox
                    isSelected={el.isSelected}
                  />
                )
              })}
            </div>
          </div>}
        <div className='flex center ds-w-100 ds-pb-12'>
          <ButtonWithIcon text={"INVITE MY CONTACTS"} textType="type-black" onClick={props.onClickShare} />
        </div>
      </div>
    </div>
  )
}

export default TemplateContactList