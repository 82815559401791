import React, { useEffect, useState } from 'react';
import { Text } from '../../Atoms';
import { getBase64, getComponentProps, render2Cercles } from '../../common';
import { error } from '../../common/validationMessage';
import { ButtonWithIcon, Header, InputFile, MoleculeInput } from '../../Molecules';
import { imageUser } from '../../Config/Config';
import { scrollToTop } from '../../Redux/authActions/authActions';
import { useDispatch } from 'react-redux';

const TemplateProfile = (props) => {
    const dispatch = useDispatch()
    const [img, setImg] = useState(null)
    const renderImageValue = () => {
        let src = null
        if (typeof props.state.image === "string")
            src = props.state.image
        else if (typeof props.state.image === "object") {
            src = img
        }
        return src
    }
    useEffect(() => {
        if (typeof props.state.image === "object") {
            getBase64(props.state.image).then(src => { setImg(src) })
        }
    }, [props.state.image])
    useEffect(() => {
        dispatch(scrollToTop())
    }, [])
    return (
        <div className="ds-w-100 ds-h-100 relative">
            {render2Cercles()}
            <div className='ds-px-20'>
                <Header
                    share={false}
                    text="Edit Profile"
                    textType={"type-2"}
                    type="type2"
                    edit={false}
                    onClickBack={() => props.onClickBack()}
                    onClickEdit={() => props.onClickChangeMode()}
                />
                <div className='flex center ds-w-100'>
                    <div className='flex-col center ds-w-max relative'>
                        {props.state.image === imageUser + null && (img === null)
                            ? <>

                                <div className="profieimg border-radius-50 ds-mr-20 relative flex center" >
                                    <Text type={"type-26"} className="absolute">{props.state?.firstName?.value.slice(0, 1) + props.state?.lastName?.value.slice(0, 1)}</Text>
                                </div>
                                <div className="image-upload ds-wp-54 ds-hp-54 absolute flex center camera-ph" >
                                    <InputFile
                                        onChange={(e) => props.onChange("image", e.target.files[0])}
                                        value={renderImageValue()}
                                        className={""}
                                        icon={"Plus-2"}
                                        iconClassName={"camera-icon"}
                                    />
                                </div>
                            </>
                            : <>
                                <div className='profile flex center relative ds-mt-40 ds-mb-15' >
                                    <img src={renderImageValue()}
                                        className="ds-w-100 ds-h-100 object-cover"
                                    />
                                </div>
                                <div className="image-upload ds-wp-54 ds-hp-54 absolute flex center camera-ch">
                                    <InputFile
                                        onChange={(e) => props.onChange("image", e.target.files[0])}
                                        value={renderImageValue()}
                                        className={""}
                                        icon={"Plus-2"}
                                        iconClassName={"camera-icon"}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            {props?.state?.firstName?.value && props?.state?.lastName?.value && <Text className={"ds-mb-39 ds-mt-15 flex center"} type={"type-7"}>
                {props?.state?.firstName?.value + " " + props?.state?.lastName?.value}
            </Text>}
            <div className='ds-pr-20 ds-pb-50 ds-pl-14 ds-w-100'>
                <MoleculeInput
                    label={"First name*"}
                    containerClassName={"ds-mb-10"}
                    onChange={(e) => props.onChange("firstName", e.target.value)}
                    {...getComponentProps(props?.state?.firstName)}
                />
                <MoleculeInput
                    label={"Last name*"}
                    onChange={(e) => props.onChange("lastName", e.target.value)}
                    containerClassName={"ds-mb-10"}
                    {...getComponentProps(props?.state?.lastName)}
                />
                <MoleculeInput
                    label={"Email*"}
                    onChange={(e) => props.onChange("email", e.target.value)}
                    {...getComponentProps(props?.state?.email)}
                    containerClassName={"ds-mb-10"}
                    type="email"
                />
                <MoleculeInput
                    label={"Password*"}
                    onChange={(e) => props.onChange("password", e.target.value)}
                    containerClassName={"ds-mb-10"}
                    {...getComponentProps(props?.state?.password)}
                    isPassword
                />
                <MoleculeInput
                    label={"Mobile phone"}
                    onChange={(e) => props.onChange("phone", e.target.value)}
                    containerClassName="ds-mb-40"
                    {...getComponentProps(props?.state?.phone)}
                    type="tel"
                />
                <ButtonWithIcon
                    onClick={props.onSubmit}
                    className={"type-2 ds-mt-20"}
                    text="SAVE"
                    isLoading={props.isLoading}
                />
            </div>
        </div>
    )
}

export default TemplateProfile