const initialState = {
  token: "",
  refreshToken: "",
  user: "",
  scroll: false
};

function AuthReducer(state, action) {
  if (!state) {
    state = initialState
  }
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        token: action.value.token,
        user: action.value.user,
        refreshToken: action.value.refreshToken
      };
    case "SET_TOKEN":
      return {
        ...state,
        token: action.value.token,
        refreshToken: action.value.refreshToken
      };
    case "RESET_TOKEN":
      return { ...initialState }
    case "SET_SCROOL":
      return { ...state, scroll: !state.scroll }
    default:
      return state;
  }
}

export default AuthReducer;
