import React, { useEffect, useState } from 'react'
import { ButtonWithIcon, Header, SwitchInput } from '../../Molecules'
import { Text } from '../../Atoms';
import { StatusList, Card } from './../../Organisme';
import { onFormatingDate, renderCercleType2 } from '../../common';
import { imagePath } from '../../Config/Config';
import { scrollToTop } from '../../Redux/authActions/authActions';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';
const status = [
    {
        key: 0,
        text: "Events created",
    },
    {
        key: 1,
        text: "Events I’ve been invited to",
    }
];
const TemplateEvents = (props) => {
    const ref = useRef()
    const [right, setRight] = useState(12)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(scrollToTop())
    }, [])
    let list = props.invitedEvents
    if (props.selectedEventType === 0) { list = props.myEvents }
    const getPosition = () => {
        if (ref?.current?.offsetLeft) {
            return ((window.innerWidth - ref?.current?.clientWidth) / 2) + 12 + "px"
        }
        return right + "px"
    }
    useEffect(() => {
        window.addEventListener('resize', function () {
            if (window.innerWidth > 768) {
                setRight(ref?.current?.offsetLeft + ref?.current?.clientWidth - 12)
            } else {
                setRight(12)
            }
        })
    }, [])
    return (
        <div className='ds-px-20 ds-pb-20 ds-w-100 relative' ref={ref}>
            {renderCercleType2()}
            {props.selectedEventType === 0 && <div className='fixed btn-add-event' style={{ right: getPosition() }}>
                <ButtonWithIcon
                    onClick={() => props.onCreateEvent()}
                    className={"type-6 flex center pointer"}
                    icon="Plus"
                    iconSize={30}
                    iconClassName={"ds-mr-0 add-icon"}
                />
            </div>}
            <Header
                type={"type2"}
                edit={false}
                share={false}
                text="My events"
                onClickBack={() => props.onClickBack()}
            />
            <div>
                <div className='flex ds-mb-20 space-between white-space'>
                    <StatusList
                        list={status}
                        selected={props.selectedEventType}
                        onClickType={props.onClickEventType}
                    />
                </div>
                <div className='ds-mt-20 flex align-center'>
                    <SwitchInput
                        checked={props.isChecked}
                        onchangeHandle={() => props.onHandleChange()}
                    />
                    <Text className={"ds-ml-21"}>Filter by ascending date</Text>
                </div>
                <div className='ds-w-100'>
                    {
                        list?.map((el, i) =>
                            <div className='ds-mt-20 ds-mb-30' key={i}>
                                <Card
                                    type={"type2"}
                                    photo={imagePath + el.imageName}
                                    classNameCard={"flex-col align-start ds-pb-16 flex-grow card-type2"}
                                    date={onFormatingDate(el.start_date)}
                                    event={el.label}
                                    status={el.statusText}
                                    onClickSeeEvent={() => props.onClickSeeEvent(i)}
                                />
                            </div>
                        )}
                </div>
            </div>
        </div >
    )
}

export default TemplateEvents