import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Text } from '../../Atoms'
import { getComponentProps, LOGO, render2Cercles } from '../../common'
import { ButtonWithIcon, MoleculeInput } from '../../Molecules'
import { scrollToTop } from '../../Redux/authActions/authActions'

const TemplateResetPassword = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(scrollToTop())
    }, [])
    return (
        <div className="ds-pr-20 ds-pl-20 ds-pb-50 ds-w-100 ds-h-100 relative flex-col space-between">
            {render2Cercles()}
            <div className="ds-pt-100 center flex-col">
                <img src={LOGO} className="logo flex justify-center ds-hp-72 z-index-1 ds-w-100" />
                <Text type="type-5" className="z-index-1">The easiest way to plan an event</Text>
            </div>
            <div className="ds-w-100 flex-gr-1 flex-col center">
                {props.isValidToken === true ? <>
                    <div className="z-index-1 ds-w-100">
                        <MoleculeInput
                            label="new password"
                            className="z-index-1"
                            onChange={(e) => props.onChange("password", e.target.value)}
                            onBlur={(e) => props.onBlur("password", e.target.value)}
                            {...getComponentProps(props?.state?.password)}
                            isPassword
                        />
                    </div>
                    <div className="ds-mt-20 z-index-1 ds-w-100">
                        <MoleculeInput
                            label="repeat new password"
                            onChange={(e) => props.onChange("repeatPassword", e.target.value)}
                            onBlur={(e) => props.onBlur("repeatPassword", e.target.value)}
                            {...getComponentProps(props?.state?.repeatPassword)}
                            isPassword
                        />
                    </div>
                </> :
                    props.isValidToken === false ?
                        <Text type="type-6">
                            {"This link is not valid any more"}
                        </Text>
                        : null}
            </div>
            <div className='ds-w-100'>
                <ButtonWithIcon
                    text={props.isValidToken ? "CONFIRM" : "SIGNIN"}
                    textType="type-black"
                    className="ds-w-100"
                    onClick={props.isValidToken ? props.onSubmit : props.onRedirect}
                    isLoading={props.isLoading}
                />
            </div>
        </div>
    )
}

export default TemplateResetPassword