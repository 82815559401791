import { apiURL } from '../Config/Config';
import { setToken, setUser } from '../Redux/authActions/authActions';
import Store from '../Redux/Store';

const createHeader = (token, contentType = 'application/json', withouttoken = false) => {
  if (contentType === 'form-data') {
    return {
      Authorization: 'Bearer ' + token
    };
  } else if (!withouttoken) {
    return {
      'Content-Type': contentType,
      accept: 'application/json',
      Authorization: 'Bearer ' + token
    };
  } else {
    return {
      'Content-Type': contentType,
      accept: 'application/json',
    };
  }
};
async function processResponse(response, callback, isTextHtml = false) {
  const refreshToken = Store.getState().auth.refreshToken;
  let responseJson;
  try {
    if (isTextHtml === true) {
      responseJson = await response.text();
    } else {
      responseJson = await response.json();
    }
  } catch (e) {
    return { code: 500 };
  }
  if (responseJson.code === 401) {
    if(responseJson.message==="JWT Token not found"){
      return callback()
    }
    const res = await apiRefreshToken(refreshToken);
    if (res.code === 200) {
      Store.dispatch(setToken(res));
      return callback();
    } else {
      Store.dispatch(
        setUser({ token: '', refreshToken: '', user: null })
      );
      return null;
    }
  } else if (responseJson.code === 200) {
    return responseJson;
  } else {
    return responseJson;
  }
}

export async function apiGet(api, acceptType = 'application/json') {
  const token = Store.getState().auth.token;
  const response = await fetch(apiURL + api, {
    method: 'Get',
    headers: createHeader(token)
  });
  return processResponse(response, () => apiGet(api), acceptType === 'text/html');
}

export async function apiPost(api, body, contentType = 'application/json', withouttoken = false) {
  const token = Store.getState().auth.token;
  const response = await fetch(apiURL + api, {
    method: 'POST',
    headers: createHeader(token, contentType, withouttoken),
    body: body
  });
  return processResponse(response, () => apiPost(api, body, contentType));
}

export async function apiDelete(api, body) {
  const token = Store.getState().auth.token;
  const response = await fetch(apiURL + api, {
    method: 'Delete',
    headers: createHeader(token, 'application/json'),
    body: body
  });
  return processResponse(response, () => apiDelete(api, body));
}

export async function apiPut(api, body, contentType = 'application/json') {
  const token = Store.getState().auth.token;
  const response = await fetch(apiURL + api, {
    method: 'Put',
    headers: createHeader(token, contentType),
    body: body
  });
  return processResponse(response, () => apiPost(api, body, contentType));
}
const apiRefreshToken = async () => {
  const refreshToken = Store.getState().auth.refreshToken;
  const response = await fetch(apiURL + 'token/refresh', {
    method: 'Post',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
    body: JSON.stringify({
      "refresh_token": refreshToken
    })
  });
  return response.json();
}