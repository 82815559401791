import React from "react";
import { Icon, Text } from "../../Atoms";
import PropTypes from "prop-types";
import { MoleculeStatus } from "../../Molecules";
import { EVENT } from "../../common";
const Card = (props) => {
  const renderText = () => {
    return props.event
  }
  return (
    <>
      {props.type === "type1" && (
        <div
          className="flex-col ds-pb-5 align-start flex-grow-1 ds-w-100 card-type-1"
          onClick={props.onClickSeeEvent}
        >
          <img src={EVENT} className="image-card" />
          <div className="flex center justify-between ds-mt-15 ds-pr-14 ds-pl-14 ds-pb-15 ds-w-100 flex-grow recangle">
            <Text type="type-6">{props.text}</Text>
            <div className="cercle-plus center flex-col">
              <Icon
                icon="croix"
                className="icon_back"
                size="20px"
                onClick={props.onCreateEvent}
              />
            </div>
          </div>
        </div>
      )}
      {props.type === "type2" && (
        <div
          className={`ds-pb-50 relative ${props.classNameCard}`}
          onClick={props.onClickSeeEvent}
        >
          <img src={props.photo} className="image-card" />
          <div className="flex-col align-start ds-pr-25 ds-pl-25 ds-w-100 flex-grow rectangel-info">
            <Text type="type-grey2">{props.date}</Text>
            <Text type="type-6" className={"ds-w-100 text-ellipsis "} style={{ marginBottom: 0 }} isP>
              {props.event.length > 25 ? renderText() : props.event}
            </Text>
            <MoleculeStatus status={props.status} />
          </div>
          <div
            onClick={props.onClickSeeEvent}
            className="flex-grow flex justify-between align-center ds-hp-34 ds-w-100 ds-mt-12 absolute ds-px-20 ds-pb-10 position-bottom"
          >
            <Text type="first-text type-13 pointer">See event</Text>
            <Icon
              icon="next"
              size="20"
              className={"pointer ds-hp-20 flesh-icon" + props.iconClassName}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Card;

Card.defaultProps = {
  text: "Create event",
  type: "type1",
  date: null,
  status: null,
  event: null,
  photo: EVENT,
  onClickSeeEvent: () => console.log(),
  onCreateEvent: () => console.log(),
  iconClassName: "pointer",
  classNameCard: null,
};

Card.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  classNameCard: PropTypes.string,
  event: PropTypes.string,
  iconClassName: PropTypes.string,
  photo: PropTypes.any,
  onClickSeeEvent: PropTypes.func,
  onCreateEvent: PropTypes.func,
};
