import PropTypes from "prop-types";
import React from "react";
import { Icon, Text } from "../../Atoms";
import { getComponentProps } from "../../common";
import { ButtonWithIcon, MoleculeInput, MoleculeModal } from "../../Molecules";
const ModalDelete = (props) => {
  return (
    <MoleculeModal
      show={props.showModal}
      onHide={props.onCloseModal}
      contentClassName={`default-modal  ds-w-100 modale-reply small`}
      dialogClassName="default-modal ds-w-100"
      title1={props.title1}
      closeIcon={false}
    >
      <div className="flex justify-between align-center ds-w-100 ds-mb-51">
        <Icon icon="close-1" size="30px" className="modal-close-icon ds-hp-60 forget-icon" style={{ visibility: "hidden" }} />
        <Text type="type-6" style={{ fontWeight: 500 }}>{props.title}</Text>
        <Icon icon="close-1" size="30px" className="modal-close-icon ds-hp-60 forget-icon" onClick={props.onCloseModal}/>
      </div>
      <div className="flex center ds-w-100 ds-mb-39 ds-mt-51">
        <Text type="type-9">
          {props.text}
        </Text>
      </div>
      <ButtonWithIcon
        text="CONFIRM"
        className="type-1 ds-mt-10"
        onClick={props.submit}
      />
    </MoleculeModal >
  );
};

export default ModalDelete;
