import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from "react-places-autocomplete";
import { Input, Text } from '../../Atoms';

const MoleculesPlaceAutoComplete = (props) => {
    const [address, setAddress] = useState("")
    const handleSelect = async (value) => {
        props.onChange && props.onChange(value)
        setAddress(value)
    };
    useEffect(() => {
        setAddress(props.value)
    }, [props.value])
    const className = []
    if (props.isInvalid) { className.push('invalid') }
    if (props.className) { className.push(props.className) }
    return (
        <div className='ds-w-100 relative'  >
            <div className="ds-ml-15 ds-mb-6 ds-w-100">
                <Text type="h3">{props.label}</Text>
            </div>
            <PlacesAutocomplete
                value={address}
                onChange={(e) => setAddress(e)}
                onSelect={handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <>
                        <Input className={className.join(' ')} {...getInputProps()} />
                        <div className='ds-w-100 absolute ds-text-white container-options-maps '>
                            {loading ? <div>...loading</div> : null}
                            {suggestions.map((suggestion, i) => {
                                return (
                                    <div key={i} {...getSuggestionItemProps(suggestion)} className='ds-w-100'>
                                        {suggestion.formattedSuggestion.secondaryText + ", " + suggestion.formattedSuggestion.mainText}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </PlacesAutocomplete>
            {props.isInvalid && props.errormsg && <Text className={"ds-mt-6 ds-ml-10"} type={"type-error"}>{props.errormsg}</Text>}
        </div>
    )
}

export default MoleculesPlaceAutoComplete;

MoleculesPlaceAutoComplete.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,

};
MoleculesPlaceAutoComplete.defaultProps = {
    label: null,
    onChange: () => console.log(),
};


