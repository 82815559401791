import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getComponentProps, render2Cercles } from '../../common'
import { error } from '../../common/validationMessage'
import { Header, MoleculeInput, ButtonWithIcon } from '../../Molecules'
import { scrollToTop } from '../../Redux/authActions/authActions'

const TemplateAddRelative = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(scrollToTop())
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="ds-w-100 ds-h-100 relative">
            {render2Cercles()}
            <div className='ds-px-20 ds-pb-30 ds-w-100 ds-h-100 flex-col space-between'>
                <Header
                    type={"type2"}
                    edit={false}
                    share={false}
                    text={"Add a relative"}
                    onClickBack={() => props.onClickBackEvent()}
                />
                <div className='ds-w-100 flex-gr-1 flex-col justify-center'>
                    <MoleculeInput
                        test
                        label="First name*"
                        onChange={(e) => props.onChange("firstName", e.target.value)}
                        onBlur={(e) => props.onBlur("firstName", e.target.value)}
                        {...getComponentProps(props?.state?.firstName)}
                    />
                    <div className="ds-mt-20 ds-w-100 z-index-1">
                        <MoleculeInput
                            label="Last name*"
                            onChange={(e) => props.onChange("lastName", e.target.value)}
                            onBlur={(e) => props.onBlur("lastName", e.target.value)}
                            {...getComponentProps(props?.state?.lastName)}
                        />
                    </div>
                    <div className="ds-mt-20 ds-w-100 z-index-1">
                        <MoleculeInput
                            label="Email*"
                            onChange={(e) => props.onChange("email", e.target.value)}
                            onBlur={(e) => props.onBlur("email", e.target.value)}
                            {...getComponentProps(props?.state?.email)}
                            type="email"
                        />
                    </div>
                </div>
                <div className='ds-w-100 ds-pt-100'>
                    <ButtonWithIcon
                        text="ADD TO THE LIST"
                        textType="type-black"
                        onClick={props.onAddRelative}
                        disabled={props.isBtnDisabled}
                    />
                </div>

            </div>
        </div>

    )
}

export default TemplateAddRelative