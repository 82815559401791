import React from 'react'
import PropTypes from 'prop-types';
import { Modal, Row } from 'react-bootstrap';
import { Icon, Text } from '../../Atoms';

MoleculeModal.defaultProps = {
    show: false,
    onHide: null,
    onHideWithReset: null,
    resetDataWithClickOutSide: false,
    contentClassName: '',
    dialogClassName: '',
    closeIcon: true,
    centered: true,
    closeIconClassName: '',
    title1: "",
    children: null
};
MoleculeModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.any,
    onHideWithReset: PropTypes.any,
    resetDataWithClickOutSide: PropTypes.bool,
    contentClassName: PropTypes.string,
    dialogClassName: PropTypes.string,
    title1: PropTypes.string,
    closeIcon: PropTypes.bool,
    centered: PropTypes.bool,
    closeIconClassName: PropTypes.string,
    children: PropTypes.any,
};

function MoleculeModal(props) {
    return (
        <Modal
            show={props.show}

            centered={props.centered}
            contentClassName={props.contentClassName}
            dialogClassName={props.dialogClassName}
            onHide={
                props.resetDataWithClickOutSide === true
                    ? props.onHideWithReset
                    : props.onHide
            }
        >
            <Modal.Body>
                {
                    props.closeIcon &&
                    <Row className='flex center align-center' onClick={
                        props.resetDataWithClickOutSide === true
                            ? props.onHideWithReset
                            : props.onHide
                    }>
                        {props.title1 && <Text type="type-7" className={"flex center "}>{props.title1}</Text>}
                        <Icon icon="close-1" size="60" className="modal-close-icon absolute ds-hp-60 forget-icon" />
                    </Row>
                }
                {props.children}
            </Modal.Body>
        </Modal>
    )
}

export default MoleculeModal