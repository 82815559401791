import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TemplateGuestList } from '../../Templates';
import { useDispatch, useSelector } from 'react-redux';
import { apiEditReplay, apiGetEvent, apiGetSubscription, apiGetSubscriptionStatus } from '../../Api/Events/events';
import moment from 'moment';
import { setEventId } from '../../Redux/EventActions/EventActions';
import { GenericOrganismModal, ModalDelete } from '../../Organisme';

const PageGuestList = () => {
    const navigate = useNavigate()
    const params = useParams();
    const location = useLocation();
    const { state: locationState } = location;
    if (locationState?.relaod === undefined) {
        navigate(`/guestList/${params.id}`, { state: { relaod: true } });
    }
    const dispatch = useDispatch();
    const { user } = useSelector(reduxState => reduxState.auth)
    const { token } = useSelector((reduxState) => reduxState.auth);
    const { eventId } = useSelector(state => state.event)
    const [contact, setContact] = useState([])
    const [selectedStatus, setSelectedStatus] = useState(1)
    const [counts, setCounts] = useState({})
    const [userList, setUserList] = useState([])
    const [currentEvent, setCurrentEvent] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenReplyPopup, setIsOpenReplyPopup] = useState(false);
    const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);
    const [response, setResponse] = useState("Attending");
    const [elementGuest, setElementGuest] = useState(null)
    const [status] = useState([
        {
            key: 1,
            text: "Attending",
        },
        {
            key: 2,
            text: "Maybe",
            selected: false,
        },
        {
            key: 3,
            text: "Notattending",
        },
        {
            key: 0,
            text: "All",
        }
    ])
    const onGetGuestList = async () => {
        const response = await apiGetSubscription(eventId)
        if (response.statusCode === 200) {
            setContact(response?.data?.subscriptions)
        }
    }
    const onClickType = (index) => {
        setSelectedStatus(index)
        getGuestList(index)
    }
    const getGuestList = async (index) => {
        setIsLoading(true)
        const response = await apiGetSubscriptionStatus(eventId, index)
        if (response?.statusCode === 200) {
            setUserList(response?.data?.subscriptions)
            setCounts({
                countAll: response?.data?.countAll,
                countAttending: response?.data?.countAttending,
                countMaybe: response?.data?.countMaybe,
                countNotAttending: response?.data?.countNotAttending,
            })
        }
        setIsLoading(false)
    }

    const onGetEventDetails = async () => {
        const res = await apiGetEvent(eventId)
        if (res?.statusCode === 200) {
            setCurrentEvent(res?.data);
        } else {
            if (token) {
                navigate("/home");
            } else {
                navigate("/");
            }
        }

    }
    useEffect(() => {
        if (eventId) {
            callAPis()
        }
    }, [eventId])
    useEffect(() => {
        callAPis()
    }, [])
    const callAPis = async (getEvent = true) => {
        await getGuestList(selectedStatus)
        await onGetGuestList()
        if (getEvent) {
            await onGetEventDetails()
        }
    }
    const getDescriptionShare = () => {
        if (currentEvent) {
            const res = `Hi! You are invited to ${currentEvent?.user?.firstname}'s event. Date : ${moment(currentEvent.start_date).format("MMMM DD, YYYY")}. To see more info and reply please click on the link below.`
            return res
        }
        return null
    }
    const redirect = () => {
        dispatch(setEventId(params.id));
        navigate(`/contacts`, { state: { fromGuest: true } });
    }
    const onEditGuest = (el, e) => {
        if (e === "delete") {
            setIsOpenDeletePopup(true)
        } else {
            setIsOpenReplyPopup(true)
            setResponse(onSetStatus(el.status))
        }
        setElementGuest(el)
    }
    const onGetStatus = () => {
        switch (response) {
            case "Attending":
                return 1;
            case "Maybe":
                return 2;
            case "Not Attending":
                return 3;
            default:
                break;
        }
    };
    const onSetStatus = (status) => {
        switch (status) {
            case 1:
                return "Attending";
            case 2:
                return "Maybe";
            case 3:
                return "Not Attending";
            default:
                break;
        }
    };
    const onConfirmResponse = async () => {
        const res = await apiEditReplay(JSON.stringify({
            "event_id": currentEvent.id,
            "user_id": elementGuest?.user?.id ? elementGuest?.user?.id : elementGuest.id,
            isUser: elementGuest?.user?.id ? 1 : 2,
            "status": onGetStatus()
        }))
        if (res.statusCode === 201) {
            onClosePopUp(true)
        }
    };
    const onDelete = async () => {
        const res = await apiEditReplay(JSON.stringify({
            "event_id": currentEvent.id,
            "user_id": elementGuest?.user?.id ? elementGuest?.user?.id : elementGuest.id,
            isUser: elementGuest?.user?.id ? 1 : 2,
            status: -1
        }))
        if (res.statusCode === 201) {
            onClosePopUp(true)
        }
    }
    const onClosePopUp = (onCallApi = false) => {
        setIsOpenReplyPopup(false)
        setIsOpenDeletePopup(false)
        setElementGuest(null)
        setResponse("Attending")
        onCallApi && callAPis(false)
    }
    const getUser = (key) => {
        if (elementGuest?.user?.[key]) {
            return elementGuest?.user?.[key]
        }
        return elementGuest[key]
    }
    return (
        <>
            <TemplateGuestList
                contact={contact}
                eventId={eventId}
                onClickBack={() => navigate(`/eventDetails/${eventId}`)}
                onClickAddGuest={() => redirect()}
                status={status}
                selectedStatus={selectedStatus}
                onClickType={onClickType}
                userList={userList}
                counts={counts}
                getDescriptionShare={getDescriptionShare}
                isLoading={isLoading}
                currentEvent={currentEvent}
                onEditGuest={onEditGuest}
            />
            {isOpenDeletePopup && <ModalDelete
                showModal={isOpenDeletePopup}
                onCloseModal={() => onClosePopUp()}
                title="Delete guest"
                text={`Remove ${getUser('firstname') + " " + getUser('lastname')} from the guest list ?`}
                submit={onDelete}
            />}
            {isOpenReplyPopup && <GenericOrganismModal
                showImg
                name={getUser('firstname').slice(0, 1) + "" + getUser('lastname').slice(0, 1)}
                pseudo={getUser('firstname') + " " + getUser('lastname')}
                showModal={isOpenReplyPopup}
                onCloseModal={() => onClosePopUp()}
                eventCreated={false}
                text={""}
                title1={"Edit status"}
                btnText={"CONFIRM"}
                moldeculeClassName={"modale-reply"}
                resetPassword={false}
                iconDone={false}
                reply={true}
                onClickFirstBtn={onConfirmResponse}
                onClickAttending={() => setResponse("Attending")}
                onClickMaybe={() => setResponse("Maybe")}
                onClickNotAttending={() => setResponse("Not Attending")}
                response={response}
                showAddRelative={true}
            />}
        </>
    )
}


export default PageGuestList