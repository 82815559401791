import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TemplateProfile } from "../../Templates";
import { imageUser } from "../../Config/Config";
import { validateEmail, validNumber } from "../../Validation";
import { defineState } from "../../common";
import { apiGetUser, apiUpdateUser } from "../../Api/User/User";
import { error } from "../../common/validationMessage";

const PageProfile = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState({
    image: "",
    firstName: { ...defineState("") },
    lastName: { ...defineState("") },
    email: { ...defineState("") },
    password: { ...defineState("") },
    phone: { ...defineState("") },
  });

  const onChange = (key, value) => {
    const aux = { ...state };
    if (key !== "image") {
      aux[key].value = value;
      aux[key].isInvalid = false;
    }
    const isEmpty = value === "";
    if (key === "email") {
      const isMail = validateEmail(value);
      if (!isMail || isEmpty) {
        aux[key].isInvalid = true;
        if (isEmpty) {
          aux[key].errormsg = error.emptyMail;
        } else {
          aux[key].errormsg = error.checkMail;
        }
      }
    } else if (key === "phone") {
      const isNumber = validNumber(value);
      const toInt = parseInt(value);
      if (isEmpty) {
        // if (!isNumber || isEmpty || toInt === 0) {
        if (isEmpty) {
          aux[key].errormsg = error.emptyPhone;
        } else {
          aux[key].errormsg = error.checkPhone;
        }
        aux[key].isInvalid = true;
      }
    } else if (key === "image") {
      aux[key] = value;
    } else {
      aux[key].isInvalid = isEmpty;
    }
    setState(aux);
  };

  const onGetUser = async () => {
    const response = await apiGetUser();
    if (response.statusCode === 200) {
      setState({
        ...state,
        firstName: {
          ...state.firstName,
          value: response.data.firstname,
        },
        lastName: {
          ...state.firstName,
          value: response.data.lastname,
        },
        email: {
          ...state.email,
          value: response.data.email,
        },
        phone: {
          ...state.phone,
          value: response.data.phone,
        },
        image: imageUser + response.data.imageName,
      });
    }
  };

  const onSubmit = async () => {
    setIsLoading(true)
    if (
      !state.firstName.isInvalid &&
      !state.lastName.isInvalid &&
      !state.phone.isInvalid &&
      !state.email.isInvalid &&
      !state.password.isInvalid &&
      parseInt(state?.phone?.value) !== 0
    ) {
      const formData = new FormData();
      formData.append("firstname", state.firstName.value);
      formData.append("lastname", state.lastName.value);
      formData.append("email", state.email.value);
      formData.append("password", state.password.value);
      formData.append("phone", state.phone.value);
      formData.append("image_file", state.image);
      const response = await apiUpdateUser(formData);
      if (response.statusCode === 200) {
        navigate("/menu");
      } else {
        let errors = {};
        if (
          response.detail.includes(
            "phone: Your phone must contain only numbers"
          )
        ) {
          errors.phone = error.checkPhone;
        }
        onChangeErrorMsg(errors);
      }
    } else {
      onChangeErrorMsg();
    }
    setIsLoading(false)
  };
  const onChangeErrorMsg = ({
    email = null,
    password = null,
    phone = null,
    firstName = null,
    lastName = null,
  }) => {
    setState({
      ...state,
      firstName: {
        ...state.firstName,
        isInvalid: false,
        errormsg: firstName,
      },
      lastName: {
        ...state.lastName,
        isInvalid: false,
        errormsg: lastName,
      },
      phone: {
        ...state.phone,
        isInvalid: false,
        errormsg: phone,
      },
      password: {
        ...state.password,
        isInvalid: false,
        errormsg: password,
      },
      email: {
        ...state.email,
        isInvalid: email ? true : false,
        errormsg: email,
      },
    });
  };
  useEffect(() => {
    onGetUser();
  }, []);
  return (
    <TemplateProfile
      state={state}
      onChange={onChange}
      onClickBack={() => navigate("/menu")}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
};

export default PageProfile;
