import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text } from '../../Atoms'
import { getShareText, render2Cercles, share } from '../../common'
import { error } from '../../common/validationMessage'
import { imageUser } from '../../Config/Config'
import { ButtonWithIcon, Header } from '../../Molecules'
import { Contact, StatusList } from '../../Organisme'

const TemplateGuestList = (props) => {
    const { user } = useSelector(state => state.auth)
    const containerRef = useRef()
    const headerContainerRef = useRef()
    const btnContainerRef = useRef()
    const listContainerRef = useRef()
    const [list, setList] = useState([])
    useEffect(() => {
        setList(props.userList)
    }, [props.userList])
    useEffect(() => {
        window?.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            window?.scrollTo({ top: 10, behavior: 'smooth' });
            setTimeout(() => {
                window?.scrollTo({ top: 0, behavior: 'smooth' });
            }, 20);
        }, 400);

    }, [])
    const getStyle = () => {
        if (containerRef?.current?.offsetHeight) {
            return { height: containerRef?.current?.offsetHeight - headerContainerRef?.current?.offsetHeight - btnContainerRef?.current?.offsetHeight }
        }
        return null
    }
    return (
        <div className='ds-w-100 ds-h-100 relative' ref={containerRef}>
            {render2Cercles()}
            <div className='ds-px-20 ds-w-100 ds-h-100 flex-col space-between'>

                <div className='ds-w-100' ref={headerContainerRef}>
                    <Header
                        type="type2"
                        textType="type-7"
                        text="Guest list"
                        edit={false}
                        share={false}
                        onClickBack={() => props.onClickBack()}
                    />
                    <div className="flex ds-w-100 ds-mb-30 overflow-y overflow-x">
                        <StatusList
                            selected={props.selectedStatus}
                            list={props.status}
                            onClickType={props.onClickType}
                            length={props.userList.countAll}
                            counts={props.counts}
                            isCountable={true}
                        />
                    </div>
                </div>
                {!props.isLoading ? list?.length === 0 ?
                    <Text type={"type-2"} className="ds-w-100 ds-h-100 ds-pb-16 flex center text-center">{error.noGuest}</Text> :
                    <div className="overflow-y ds-w-100 flex-gr-1" id="style-1" ref={listContainerRef} style={getStyle()}>
                        <div className='center ds-w-100'>
                            {list.map((el, i) => {
                                return (
                                    <>
                                        <Contact
                                            name={el.user.firstname.slice(0, 1) + "" + el.user.lastname.slice(0, 1)}
                                            key={i}
                                            avatar={el.user.imageName !== null ? imageUser + el.user.imageName : ""}
                                            pseudo={el.user.firstname + " " + el.user.lastname}
                                            showAction={user?.id === props.currentEvent?.user?.id}
                                            onClickAdd={(e) => props.onEditGuest(el, e)}
                                        />
                                        {el?.relatives?.map((item, j) => {
                                            return (
                                                <Contact
                                                    key={j}
                                                    pseudo={item?.firstname + " " + item?.lastname}
                                                    isAdded={true}
                                                    showAction={user?.id === props.currentEvent?.user?.id}
                                                    onClickAdd={(e) => props.onEditGuest(item, e)}
                                                />
                                            )
                                        })}

                                    </>
                                )
                            })}
                        </div>
                    </div>

                    : null}
                <div className='ds-w-100 ds-pb-18' ref={btnContainerRef}>
                    <ButtonWithIcon
                        text={"ADD GUESTS FROM CONTAT LIST"}
                        className="type-2 ds-mb-10"
                        icon={"Plus-1"}
                        iconSize={30}
                        onClick={props.onClickAddGuest}
                    />
                    <ButtonWithIcon
                        text={getShareText()}
                        className="type-2"
                        icon={"link"}
                        iconSize={30}
                        onClick={() => share({ id: props.eventId, description: props.getDescriptionShare() })}
                        isShare
                    />
                </div>
            </div>
        </div>

    )
}

export default TemplateGuestList