import { apiURL } from '../../Config/Config';
import { apiPost } from '../standardApi';
export async function apiSignin(email, password) {
    const res = await fetch(apiURL + 'login_check', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
        },
        body: JSON.stringify({
            username: email,
            password: password,
        })
    })
    return res.json()
}

export async function apiConfirmAccount(confirmToken) {
    const res = await fetch(apiURL + 'confirmAccount', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
        },
        body: JSON.stringify({
            confirmation_token: confirmToken,
        })
    })
    return res.json()
}

export async function apiForgotPassword(body) {
    const res = await fetch(apiURL + 'forgotPassword', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
        },
        body: body
    })
    return res.json()
}
export async function apiResetPassword(body) {
    const res = await fetch(apiURL + 'resetPassword', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
        },
        body: body
    })
    return res.json()
}
export async function apiCheckPassword(body) {
    const res = await fetch(apiURL + 'verify-password-token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
        },
        body: body
    })
    return res.json()
}