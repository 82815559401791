import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Input, Text } from '../../Atoms';

const InputFile = (props) => {
    const ref = useRef();
    const [file, setFile] = useState(null)
    const onClick = () => {
        ref?.current?.click()
    }
    const className = ['pointer']
    if (props.isInvalid) { className.push('default-input-file-invalid') }
    if (props.className) { className.push(props.className) }
    const onChange = (e) => {
        if (e.target.files?.length) {
            setFile(e.target.files[0])
        }
        props.onChange && props.onChange(e)
    }
    const renderName = () => {
        if (file?.name)
            return file?.name
        return props.text
    }
    return (
        <>
            <div className={className.join(' ')} onClick={onClick}>
                <Input ref={ref} type={"file"} onChange={onChange} isInvalid={props.isInvalid} />
                {props.text && <Text type={"type-18"} >{renderName()}</Text>}
                {props.icon && <Icon icon={props.icon} iconSize={30} className={props.iconClassName} />}

            </div>
            {props.isInvalid && props.errormsg && <Text className={"type-erro"}>{props.errormsg}</Text>}
        </>
    )
}

export default InputFile
InputFile.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onClickBox: PropTypes.func,
    onClickInput: PropTypes.func,
    refInput: PropTypes.any,
    style: PropTypes.any,
    icon: PropTypes.string,
    iconClassName: PropTypes.string,
    errormsg: PropTypes.string
};
InputFile.defaultProps = {
    text: "",
    className: "upload-box flex align-center justify-between ds-px-20 ds-mt-20 ds-w-100 relative",
    onChange: () => console.log(),
    onClickBox: () => console.log(),
    onClickInput: () => console.log(),
    style: null,
    refInput: null,
    icon: null,
    iconClassName: "upload-icon",
    errormsg: null

};