import React from 'react'
import { useNavigate } from 'react-router-dom';
import { TemplateWelcome } from '../../Templates';
const PageWelcome = () => {
  const navigate = useNavigate();
  return (
    <TemplateWelcome
      onCreateEvent={() => navigate("/event")}
      onClickSignIn={() => navigate("/signin")}
    />
  )
}
export default PageWelcome