import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { TemplateEvents } from '../../Templates';
import { apiGetMyEvents, apiGetMyEventsFiltred } from '../../Api/Events/events';

const PageEvents = () => {
  const navigate = useNavigate()
  const [myEvents, setMyEvents] = useState([])
  const [invitedEvents, setInvitedEvents] = useState([])
  const [selectedEventType, setSelectedEventType] = useState(0)
  const [isChecked, setIsChecked] = useState(false);

  const onHandleChange = async () => {
    const response = await apiGetMyEventsFiltred(!isChecked)
    if (response?.data?.createdEvents?.length) {
      setMyEvents(response?.data?.createdEvents)
    }
    if (response?.data?.invitedEvents?.length) {
      setInvitedEvents(response?.data?.invitedEvents)
    }
    setIsChecked(!isChecked)
  }
  const onGetMyEvents = async () => {
    if (isChecked === false) {
      const response = await apiGetMyEvents()
      if (response.statusCode === 200) {
        if (response?.data?.createdEvents?.length) {
          setMyEvents(response?.data?.createdEvents)
        }
        if (response?.data?.invitedEvents?.length) {
          setInvitedEvents(response?.data?.invitedEvents)
        }
      }

    }
  }
  const onClickSeeEvent = (index) => {
    if (selectedEventType === 0) {
      navigate(`/eventDetails/${myEvents[index]?.id}`, { state: { fromEvents: true } })
    } else {
      navigate(`/eventDetails/${invitedEvents[index]?.id}`, { state: { fromEvents: true } })
    }

  }
  const onCreateEvent = () => {
    navigate(`/event`)
  }
  useEffect(() => {
    onGetMyEvents()
  }, [])
  return (
    <TemplateEvents
      onClickBack={() => navigate("/menu")}
      isChecked={isChecked}
      myEvents={myEvents}
      invitedEvents={invitedEvents}
      selectedEventType={selectedEventType}
      onClickEventType={(i) => setSelectedEventType(i)}
      onClickSeeEvent={onClickSeeEvent}
      onHandleChange={onHandleChange}
      onCreateEvent={onCreateEvent}
    />
  )
}

export default PageEvents