import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { TemplateContactList } from '../../Templates';
import { GenericOrganismModal } from '../../Organisme';
import { useSelector } from 'react-redux';
import { apiGetContacts, apiAddSubscription } from '../../Api/Contact/Contact';

const PageContactList = () => {
  const navigate = useNavigate()
  const eventId = useSelector(state => state.event.eventId)
  const [contactsList, setContactsList] = useState([])
  const [openPop, setOpenPop] = useState(false);
  const location = useLocation();
  const { state: eventState } = location;

  const onAddContact = (index) => {
    const aux = [...contactsList]
    aux[index] = { ...aux[index], isSelected: !aux[index].isSelected }
    setContactsList(aux);
  }

  const onOpenPopup = () => {
    setOpenPop(true)
  }
  const onClosePopup = () => {
    setOpenPop(false)
  }
  const onClickShare = async () => {
    const list = contactsList.filter(el => el.isSelected)
    if (list?.length) {
      const response = await apiAddSubscription(JSON.stringify({
        event_id: eventId,
        contacts: list,
      }))
      if (response.statusCode === 200) {
        onOpenPopup()
      }
    }
  }
  const onGetListContacts = async () => {
    const response = await apiGetContacts();
    if (response?.statusCode === 200) {
      setContactsList(response?.data?.map(el => {
        return { ...el, isSelected: false }
      }))
    }
  }
  useEffect(() => {
    onGetListContacts()
  }, [])

  const onBack = () => {
    if (eventState?.fromGuest) {
      navigate(`/guestList/${eventId}`, { replace: true })
    } else {
      navigate(`/eventDetails/${eventId}`, { replace: true })
    }
  }
  return (
    <>
      <TemplateContactList
        contactsList={contactsList}
        onClickBack={onBack}
        onAddContact={onAddContact}
        onClickShare={onClickShare}
      />
      <GenericOrganismModal
        showModal={openPop}
        onCloseModal={() => onClosePopup()}
        eventCreated={false}
        text={"Your guests just received an email with a link to confirm their presence. You will be able to see who’s coming by checking the guest list on your event page."}
        title={"Invitation sent to all selected contacts"}
        btnText={"GO TO EVENT"}
        moldeculeClassName={"modale1"}
        iconDone={true}
        showAddRelative={true}
        onClickFirstBtn={() => navigate(`/eventDetails/${eventId}`, { replace: true })}
      />
    </>

  )
}

export default PageContactList