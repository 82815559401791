import React from "react";
import PropTypes from 'prop-types';
import { useRef } from "react";
const Button = (props) => {
  const ref = useRef()
  const className = ["default-btn ds-w-100 center flex pointer"];
  if (props.type) {
    className.push(props.type)
  }
  if (props.className) {
    className.push(props.className);
  }
  if (props.disabled) {
    className.push('disabled')
  } else {
    className.push('not-disabled')
  }
  const onClick = () => {
    props.onClick && props.onClick()
  }
  return (
    <>
      <button
        ref={ref}
        disabled={props.disabled}
        className={className.join(" ")}
        onClick={onClick}
        style={props.style}
      >
        {props.children}
      </button>
    </>
  );
};

Button.defaultProps = {
  text: "Create event",
  type: null,
  className: null,
  disabled: false,
  onClick: null,
  style: null,
  children: null,
};
Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.any,
  style: PropTypes.any,
  children: PropTypes.any,
};

export default Button;