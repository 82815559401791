import React from 'react';
import PropTypes from "prop-types";
import Text from '../Text/Text';

const TextArea = (props) => {
    const className = ['text-area']
    if (props.isInvalid) { className.push('invalid') }
    if (props.className) { className.push(props.className) }
    return (
        <div className='ds-w-100 flex-col'>
            <Text type="h3" className={"ds-ml-10"}>{props.label}</Text>
            <textarea
                className={className.join(' ')}
                value={props.value}
                onChange={props.onChange}
                isInvalid={props.isInvalid}
            >
            </textarea>
            {props.isInvalid && props.errormsg && <Text className={"ds-mt-6 ds-ml-10"} type={props.errormsgType}>{props.errormsg}</Text>}
        </div>
    )
}
export default TextArea;
TextArea.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    isInvalid: PropTypes.bool,
    isValid: PropTypes.bool,
    label: PropTypes.string,
    errormsg: PropTypes.string,
    errormsgType: PropTypes.string,
};
TextArea.defaultProps = {
    value: "",
    className: null,
    isInvalid: false,
    onChange: () => console.log(),
    errormsg: null,
    errormsgType: "type-error",
    label: null,
};