import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Text } from '../../Atoms';
import { REEPLY, renderCercleType2 } from '../../common';
import { ButtonWithIcon, Header } from '../../Molecules';
import { scrollToTop } from '../../Redux/authActions/authActions';

const TemplateWelcome = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(scrollToTop())
    }, [])
    return (
        <div className='ds-w-100 ds-px-20 ds-h-100 relative flex-col space-between ds-pb-50'>
            {renderCercleType2()}
            <Header
                type={"type1"}
                showIcon={false}
            />
            <div className='ds-w-100 flex-gr-1 flex-col center'>
                <div className={"flex-col"} >
                    <Text type="type-0">Welcome to,
                        <img src={REEPLY} /> !
                    </Text>
                    <Text type={"h3"}>{"Planning an event has never been easier."}</Text>
                    <Text type={"h3"}>{"Want to try ?"}</Text>
                </div>
                <div className='ds-w-100 ds-pt-34'>
                    <ButtonWithIcon text="CREATE EVENT"
                        textType="type-black"
                        icon="Plus"
                        iconSize={28}
                        iconClassName="first-icon ds-mr-20"
                        onClick={() => props.onCreateEvent()}
                    />
                </div>
            </div>
            <div className="flex center ds-w-100">
                <Text type="type-12">Already have an account?
                    <Text type="first-text type-24" className={"pointer"} onClick={() => props.onClickSignIn()}> Sign in</Text>
                </Text>
            </div>
        </div>
    )
}
export default TemplateWelcome