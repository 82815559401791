import React, { useState } from 'react'
import { validateEmail } from './../../Validation/index';
import { useNavigate } from 'react-router-dom';
import { apiForgotPassword, apiSignin } from '../../Api/Login/Login';
import { useDispatch } from 'react-redux';
import { setUser } from '../../Redux/authActions/authActions';
import { TemplateSignin } from '../../Templates';
import { GenericOrganismModal } from '../../Organisme';
import { defineState } from '../../common';
import { error } from '../../common/validationMessage';

const PageSignin = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [openPop, setOpenPop] = useState(false);
    const [openPopReset, setOpenPopReset] = useState(false);
    const [email, setEmail] = useState({ ...defineState('') });
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState({
        email: { ...defineState('') },
        password: { ...defineState('') },
    })
    const onClickNavigate = (path) => {
        navigate(path)
    }
    const onClosePopup = () => {
        setOpenPop(false)
    }
    const onBlur = (key, value) => {
        const aux = { ...state }
        const isEmpty = value === ""
        aux[key].isInvalid = false
        aux[key].value = value;
        if (key === "email") {
            const isMail = validateEmail(value)
            const check = !isMail || isEmpty
            aux[key].isInvalid = check
            if (aux.password.isInvalid) {
                if (aux.password.value) {
                    aux.password.isInvalid = false
                }
            }
        } else {
            if (isEmpty) {
                aux[key].isInvalid = true
            }
            if (aux.email.isInvalid) {
                const isMail = validateEmail(aux.email.value)
                if (isMail) {
                    aux.email.isInvalid = false
                }
            }
        }
        setState(aux)
    }
    const onChange = (key, value) => {
        const aux = { ...state }
        aux[key].isInvalid = false
        aux[key].value = value;
        setState(aux)
    }
    const onSubmit = async () => {
        setIsLoading(true)
        if (!state.email.isInvalid && !state.password.isInvalid && state.email.value !== '' && state.password.value !== "") {
            const response = await apiSignin(state.email.value, state.password.value)
            onChangeErrorMsg({})
            if (response?.statusCode === 200) {
                dispatch(setUser({
                    token: response.token,
                    refreshToken: response.refresh_token,
                    user: response.data.user,
                }))
                navigate('/home')
            } else {
                let errors = {}
                errors.email = error.checkMail
                errors.password = error.checkPassword
                onChangeErrorMsgLogin(errors)
            }
        } else {
            let errors = {}
            errors.email = error.checkMail
            errors.password = error.checkPassword
            onChangeErrorMsgLogin(errors)
        }
        setIsLoading(false)
    }
    const onForgotPassword = () => {
        setOpenPop(true)
    }
    const onChangeEmail = (value) => {
        const aux = { ...email }
        aux.value = value
        aux.errormsg = null
        aux.isInvalid = false
        setEmail(aux)
    }
    const onBlurInput = (value) => {
        const aux = { ...email }
        const isEmpty = value === ""
        aux.value = value
        aux.errormsg = null
        const isMail = validateEmail(value)
        const check = !isMail || isEmpty
        aux.isInvalid = check
        if (isEmpty) {
            aux.isInvalid = true
            aux.isValid = false
        }
        setEmail(aux)
    }
    const onClickForgotPassword = async () => {
        if (email.value !== "" && !email.isInvalid) {
            const response = await apiForgotPassword(JSON.stringify({ email: email.value }))
            if (response.statusCode === 200) {
                onChangeErrorMsg({})
                onClosePopup()
            } else if (response.statusCode === 400) {
                let errors = {}
                errors.email = error.checkMail
                onChangeErrorMsg(errors)
            }
        } else {
            let errors = {}
            errors.email = error.checkMail
            onChangeErrorMsg(errors)
        }
    }
    const onChangeErrorMsg = ({ email = null }) => {
        setEmail({
            ...email,
            isInvalid: email ? true : false,
            errormsg: email

        })
    }
    const onChangeErrorMsgLogin = ({ email = null, password = null }) => {
        setState({
            email: {
                ...state.email,
                isInvalid: email ? true : false,
                isValid: false,
                errormsg: email
            },
            password: {
                ...state.password,
                isInvalid: password ? true : false,
                isValid: false,
                errormsg: password
            }
        })
    }

    return (
        <>
            <TemplateSignin
                onChange={onChange}
                onBlur={onBlur}
                state={state}
                onClickSignUp={() => onClickNavigate("/signup")}
                onSubmit={onSubmit}
                onForgotPassword={onForgotPassword}
                isLoading={isLoading}
            />
            <GenericOrganismModal
                showModal={openPop}
                onCloseModal={() => onClosePopup()}
                eventCreated={false}
                text={"Enter your email to reset your password."}
                title1={"Reset password"}
                btnText={"GOT IT"}
                moldeculeClassName={"modale-password"}
                iconDone={false}
                resetPassword={true}
                inputValue={email}
                onChangeInput={(e) => onChangeEmail(e.target.value)}
                onBlurInput={(e) => onBlurInput(e.target.value)}
                onClickFirstBtn={() => onClickForgotPassword()}
                showAddRelative={true}
            />
        </>
    )
}

export default PageSignin