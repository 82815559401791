import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Text } from '../../Atoms';
import { UNION } from '../../common';

Header.defaultProps = {
    text: null,
    textType: "type-7",
    iconClassName: null,
    onClickMenu: null,
    style: null,
    menu: 'menu-burger',
    menusize: "50px",
    type: null,
    edit: true,
    share: true,
    onClickBack: () => console.log(),
    onClickShare: () => console.log(),
    onClickEdit: () => console.log(),
    clickedMenu: false,
    showIcon: true,
    shadowBox: false
};
Header.propTypes = {
    text: PropTypes.string,
    textType: PropTypes.string,
    iconClassName: PropTypes.string,
    onClickMenu: PropTypes.any,
    style: PropTypes.any,
    menu: PropTypes.string,
    menusize: PropTypes.any,
    type: PropTypes.string,
    edit: PropTypes.bool,
    share: PropTypes.bool,
    onClickBack: PropTypes.func,
    onClickShare: PropTypes.func,
    onClickEdit: PropTypes.func,
    clickedMenu: PropTypes.bool,
    shadowBox: PropTypes.bool
};

function Header(props) {
    const containerClassName = 'ds-w-100 ds-hp-80 flex justify-between align-center'
    return (
        <>
            {props.type === "type1" && <div className={containerClassName}>
                <img src={UNION} className="logo-header" />
                {props.showIcon && <Icon icon={!props.clickedMenu ? "menu-burger-1" : "close-1"} size={props.menusize} className={"pointer detail-icon ds-hp-30" + props.iconClassName} onClick={props.onClickMenu} />}
            </div>}
            {props.type === "type2" && <div className={containerClassName}>
                <div className={props.shadowBox ? `cercle flex-col center ds-wp-42 ds-hp-42` : `cercle flex-col center ds-wp-42 ds-hp-42`}>
                    <Icon icon="chevron-down" size="30" className="pointer icon_back detail-icon ds-hp-30" onClick={props.onClickBack} />
                </div>
                {props.text && <Text type={props.textType}>{props.text}</Text>}
                <div className='flex' >
                    {props.edit && <div className={props.shadowBox && `cercle flex-col center ds-wp-42 ds-hp-42 ds-mr-10`}>
                        <Icon icon="EDIT" size="30" onClick={props.onClickEdit} className={props.share && "pointer detail-icon ds-hp-30"} />
                    </div>
                    }
                    {props.share && <div className={props.shadowBox && `cercle flex-col center ds-wp-42 ds-hp-42`}>
                        <Icon icon="new-user" className="pointer fill-white detail-icon ds-hp-30" size="25" onClick={props.onClickShare} />
                    </div>
                    }
                </div>
            </div>}
        </>
    )
}

export default Header