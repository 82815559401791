import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { TemplateEvent } from "../../Templates";
import { imagePath } from "../../Config/Config";
import {
  defineState,
  isLater,
  onGetDay,
  onGetMonth,
  onGetYear,
} from "../../common";
import {
  apiCreateEvent,
  apiGetEvent,
  apiUpdateEvent,
} from "../../Api/Events/events";
import { setEvent } from "../../Redux/EventActions/EventActions";
import { error } from "../../common/validationMessage";
import moment from "moment";

const PageEvent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { state: locationState } = location;
  const token = useSelector((reduxState) => reduxState.auth.token);
  const { event } = useSelector((reduxState) => reduxState.event);
  const navigate = useNavigate();
  const params = useParams();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState({
    name: { ...defineState("") },
    location: { ...defineState("") },
    startingDate: { ...defineState("") },
    endingDate: { ...defineState("") },
    description: { ...defineState("") },
    image: { ...defineState(null) },
    allowRelative: false,
  });
  const onChnageSwitch = () => {
    const aux = { ...state };
    aux.allowRelative = !aux.allowRelative;
    setState(aux);
  };
  const onChange = (key, value) => {
    let aux = { ...state };
    const isEmpty = value === "";
    aux[key].value = value;
    aux[key].isInvalid = isEmpty;
    aux[key].isValid = !isEmpty;
    aux[key].errormsg = null;
    if (key === "startingDate" || key === "endingDate") {
      if (aux.endingDate.value && aux.startingDate.value) {
        if (!isLater(aux.endingDate.value, aux.startingDate.value)) {
          aux.endingDate.errormsg = error.endingDateGreater;
          aux.endingDate.isInvalid = true;
        }
      } else {
        aux.endingDate.errormsg = null;
        aux.endingDate.isInvalid = false;
      }
    }
    aux = realTimeValidation(aux, key);
    setState(aux);
  };
  const realTimeValidation = (aux, key) => {
    if (aux.name.value && aux.name.isInvalid && key !== "name") {
      aux.name.isInvalid = false;
    }
    if (aux.location.value && aux.location.isInvalid && key !== "location") {
      aux.location.isInvalid = false;
    }
    if (
      aux.startingDate.value &&
      aux.startingDate.isInvalid &&
      key !== "startingDate"
    ) {
      aux.startingDate.isInvalid = false;
    }
    if (
      aux.endingDate.value &&
      aux.endingDate.isInvalid &&
      key !== "endingDate"
    ) {
      aux.endingDate.isInvalid = false;
    }
    if (aux.endingDate.value && aux.startingDate.value) {
      if (!isLater(aux.endingDate.value, aux.startingDate.value)) {
        aux.endingDate.errormsg = error.endingDateGreater;
        aux.endingDate.isInvalid = true;
        aux.endingDate.isValid = false;
      }
    }
    if (
      aux.description.value &&
      aux.description.isInvalid &&
      key !== "description"
    ) {
      aux.description.isInvalid = false;
    }
    if (aux.image.value && aux.image.isInvalid && key !== "image") {
      aux.image.isInvalid = false;
    }
    return aux;
  };
  const onGetEventDetails = async () => {
    const response = await apiGetEvent(params.id);
    if (response?.statusCode === 200) {
      const aux = { ...state };
      aux.name.value = response.data.label;
      aux.location.value = response.data.location;
      aux.startingDate.value = new Date(response.data.start_date);
      aux.endingDate.value = new Date(response.data.end_date);
      aux.description.value = response.data.description;
      aux.image.value = response.data.imageName;
      aux.allowRelative = response.data.is_allowed_relative_add;
      setState(aux);
    } else {
      if (token) {
        navigate("/home");
      } else {
        navigate("/");
      }
    }
  };
  const getData = () => {
    return {
      name: state.name.value,
      location: state.location.value,
      startingDate:
        moment(state.startingDate.value).format("YYYY-MM-DD") +
        "T" +
        (state.startingDate.value.getHours()) +
        ":" +
        state.startingDate.value.getMinutes(),
      endingDate:
        moment(state.endingDate.value).format("YYYY-MM-DD") +
        "T" +
        (state.endingDate.value.getHours()) +
        ":" +
        state.endingDate.value.getMinutes(),
      description: state.description.value,
      image: state.image.value,
      allowRelative: state.allowRelative,
    };
  };
  const onSubmitData = async () => {
    setIsLoading(true)
    if (
      state.name.value !== "" &&
      state.location.value !== "" &&
      state.startingDate.value !== "" &&
      state.endingDate.value !== "" &&
      state.description.value !== "" &&
      !state.name.isInvalid &&
      !state.startingDate.isInvalid &&
      !state.endingDate.isInvalid &&
      !state.description.isInvalid &&
      !state.location.isInvalid
    ) {
      let body = getData();
      if (!token) {
        dispatch(setEvent(body));
        navigate("/signup", { state: { fromEvent: true, event: body } });
      } else {
        let fn = apiCreateEvent;
        let code = 201;
        if (params.id) {
          fn = apiUpdateEvent;
          body.id = params.id;
          code = 200;
        }
        const response = await fn(body);
        if (response?.statusCode === code) {
          // dispatch(setEvent(response.data));
          setEventId(response.data.id);
          if (response?.statusCode === 200) {
            onChangeErrorMsg({});
            navigate(`/eventDetails/${response.data.id}`);
          } else if (response?.statusCode === 201) {
            onChangeErrorMsg({});
            navigate(`/eventDetails/${response.data.id}`, {
              state: {
                idEventCreated: response.data.id,
                eventCreated: response.data,
              },
            });
          }
        }
      }
    } else {
      let errors = {};
      if (!state.name.value) {
        errors.name = error.emptyEventName;
      }
      if (!state.location.value) {
        errors.location = error.emptyLocation;
      }
      if (!state.startingDate.value) {
        errors.startingDate = error.emptyDate;
      }
      if (!state.endingDate.value) {
        errors.endingDate = error.emptyDate;
      }
      if (!state.description.value) {
        errors.description = error.emptyDescription;
      }
      if (!isLater(state.endingDate.value, state.startingDate.value)) {
        errors.endingDate = error.endingDateGreater;
      }
      onChangeErrorMsg(errors);
    }
    setIsLoading(false)
  };
  const onChangeErrorMsg = ({
    name = null,
    location = null,
    startingDate = null,
    endingDate = null,
    description = null,
    image = null,
  }) => {
    setState({
      ...state,
      name: {
        ...state.name,
        isInvalid: name ? true : false,
        errormsg: name,
      },
      location: {
        ...state.location,
        isInvalid: location ? true : false,
        errormsg: location,
      },
      startingDate: {
        ...state.startingDate,
        isInvalid: startingDate ? true : false,
        errormsg: startingDate,
      },
      endingDate: {
        ...state.endingDate,
        isInvalid: endingDate ? true : false,
        errormsg: endingDate,
      },
      description: {
        ...state.description,
        isInvalid: description ? true : false,
        errormsg: description,
      },
      image: {
        ...state.image,
        isInvalid: image ? true : false,
        errormsg: image,
      },
    });
  };
  const onClickBackEvent = () => {
    if (locationState?.editEvent) {
      navigate(`/eventDetails/${locationState?.idEventEdited}`);
    } else {
      if (token) {
        navigate("/home");
      } else {
        navigate("/");
      }
    }
  };
  useEffect(() => {
    if (params.id) {
      onGetEventDetails();
    }
  }, []);

  return (
    <>
      <TemplateEvent
        state={state}
        onChange={onChange}
        onChnageSwitch={onChnageSwitch}
        onSubmitData={onSubmitData}
        onClickBack={onClickBackEvent}
        isLoading={isLoading}
        id={params?.id}
      />
    </>
  );
};

export default PageEvent;
