import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { AtomDatePicker, Text } from '../../Atoms';

const Datepicker = (props) => {

  const ref = useRef();
  const onClickIcon = () => {
    ref?.current?.onInputClick();
  }
  return (
    <div className={`relative ds-w-100`}>
      {props.label && <Text className="ds-ml-10">{props.label}</Text>}
      <AtomDatePicker
        value={props.value}
        onChange={props.onChange}
        onClickIcon={onClickIcon}
        className={props.className}
        ref={ref}
        isInvalid={props.isInvalid}
      />
      {props.isInvalid && props.errormsg && <Text style={{ bottom: 0 }} className={"absolute ds-mt-6 ds-ml-10"} type={props.errormsgType}>{props.errormsg}</Text>}
    </div>
  )
}

export default Datepicker

Datepicker.propTypes = {
  selected: PropTypes.any,
  className: PropTypes.string,
  isInvalid: PropTypes.bool,
  refDate: PropTypes.string,
  onChange: PropTypes.func,
  onClickIcon: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  errormsg: PropTypes.string,
  errormsgType: PropTypes.string,

};
Datepicker.defaultProps = {
  selected: new Date(),
  className: "",
  isInvalid: false,
  onChange: () => console.log(),
  onClickIcon: () => console.log(),
  placeholder: null,
  refDate: null,
  label: null,
  errormsg: "value invalid",
  errormsgType: "type-error"

};