import React from "react";
import PropTypes from 'prop-types';

Text.defaultProps = {
    text: null,
    className: null,
    onClick: null,
    type: "h0",
    color: null,
    style: null,
    children: null,
};
Text.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    color: PropTypes.string,
    style: PropTypes.any,
    children: PropTypes.any,
};

function Text(props) {
    const className = ["default-text"];
    if (props.type) { className.push(props.type.toLowerCase()) }
    if (props.className) { className.push(props.className); }
    if(props.isP){
        return (
            <p
                onClick={props.onClick}
                className={className.join(" ")}
                style={props.style}
            >
                {props.children}
            </p>
        );
    }
    return (
        <span
            ref={props.textRef}
            onClick={props.onClick}
            className={className.join(" ")}
            style={props.style}
        >
            {props.children}
        </span>
    );
}
export default Text;
