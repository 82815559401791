const initialState = {
    eventId: "",
    event: [],
    eventToReply: [],
    copyNotification: ""
};
function EventReducer(state, action) {
    if (!state) {
        state = initialState
    }
    const list = state?.eventToReply?.length ? [...state.eventToReply] : []
    switch (action.type) {
        case "SET_EVENT_ID":
            return {
                ...state,
                eventId: action.value,
            };
        case "RESET_EVENT_ID":
            return {
                ...initialState,
                event: state.event
            };
        case "RESET_EVENT":
            return {
                ...initialState,
                event: []
            };
        case "SET_EVENT":
            if (Array.isArray(state.event)) {
                return {
                    ...state,
                    event: [action.value],
                };
            } else {
                return {
                    ...state,
                    event: [action.value],
                };
            }
        case "DELETE_EVENT":
            return {
                ...state,
                event: state.event.filter((el, i) => i !== action.value)
            }
        case "SET_EVENT_TO_REPLY":
            return {
                ...state,
                eventToReply: state?.eventToReply?.length ? [...state.eventToReply, action.value] : [action.value]
            }
        case "REMOVE_LAST_EVENT_TO_REPLY":
            if (list?.length) {
                list.pop()
            }
            return {
                ...state,
                eventToReply: list
            }
        case "DELETE_EVENT_TO_REPLAY":
            return {
                ...state,
                eventToReply: state.eventToReply.filter((el, i) => i !== action.value)
            }
        case "SET_COPY_LINk":
            return {
                ...state,
                copyNotification: action.value ? "Copied link" : ""
            }
        default:
            return state;
    }
}
export default EventReducer;







