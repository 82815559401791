import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import logger from 'redux-logger-simple';
import { persistStore, persistReducer } from 'redux-persist';
import storageLocal from 'redux-persist/lib/storage';
import AuthReducer from './Reducers/AuthReducer';
import EventReducer from './Reducers/EventReducer';
const persistConfig = {
  key: 'root',
  timeout: null,
  storage: storageLocal,
  whitelist: ["auth", "event"],
  blacklist: []
};
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(logger));
const reducers = combineReducers({
  auth: AuthReducer,
  event: EventReducer,
});
const persistedReducers = persistReducer(persistConfig, reducers);
const Store = createStore(persistedReducers, enhancer);
export default Store;
export const PERSISTOR = persistStore(Store);