import { onFormatingDate } from "../../common";
import { imagePath } from "../../Config/Config";
import Card from "../Card/Card";

export default function OrganismCarousel({ list, fn }) {
    return <div id="caroussel-container" className="ds-w-100 flex overflow-x">
        {list?.map((el, i) => {
            return (
                <div className='ds-pr-21 pointer' key={i}>
                    <Card
                        photo={`${imagePath + el.imageName}`}
                        event={el.label}
                        date={onFormatingDate(el.start_date)}
                        status={el.statusText}
                        type="type2"
                        iconClassName="ds-hp-20"
                        onClickSeeEvent={() => fn(i)}
                        classNameCard={"flex-col align-start ds-pb-16 flex-grow card-type1"}
                    />
                </div>
            );
        })}
    </div>
}