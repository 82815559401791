import { useEffect, useRef, useState } from "react";
import { Icon, Text } from "../../Atoms";
const MoleculeReadMore = (props) => {
  const ref = useRef();
  const [sliceValue, setSliceValue] = useState(0);
  const [isUpdateText, setIsUpdateText] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const [text, setText] = useState("");
  const className = [];
  const textClassName = [];
  const textMore = !isReadMore ? "...Read more" : " Show less"
  if (props.type === "type-1") {
    className.push(
      "description-box ds-px-15 ds-py-10 ds-mt-20 ds-w-100 ds-mb-20 white-space"
    );
  } else {
    className.push("description-box-map");
  }
  const replaceText = () => {
    // return props.text.replaceAll((/[\n]/g, ' '))
    return props.text
  }
  useEffect(() => {
    if (props.text) {
      setText(replaceText());
    } else {
      setText("");
    }
    setIsUpdateText(false);
    setSliceValue(0);
    setIsReadMore(false);
  }, [props.text]);
  useEffect(() => {
    if (ref?.current?.scrollHeight > ref?.current?.offsetHeight) {
      setSliceValue(sliceValue + 1);
    } else {
      if (sliceValue) {
        setText(
          replaceText().slice(0, replaceText()?.length - sliceValue ) + textMore
        );
      }
      setIsUpdateText(true);
    }
  }, [text]);
  useEffect(() => {
    if (sliceValue) {
      setText(replaceText().slice(0, replaceText()?.length - sliceValue) + textMore);
    }
  }, [sliceValue]);
  const getByText = () => {
    if(isUpdateText){

      const list = text.split('')
      for (let index = 0; index < textMore.length; index++) {
        list.pop()
      }
      return list.join("")
    }
    return text
  }
  const renderText = () => {
    if (sliceValue) {
      if (!isReadMore) {
        return getByText();
      }
      return replaceText();
    }
    return replaceText();
  };
  const renderMore = () => {
    if (sliceValue && isUpdateText) {
      return (
        <Text
          className={"pointer"}
          type={"first-text"}
          onClick={() => setIsReadMore(!isReadMore)}
        >
          {textMore}
        </Text>
      );
    }
    return null;
  };
  if (isUpdateText) {
    if (sliceValue) {
      if (props.type === "type-1") {
        className.push("open");
      } else {
        if (isReadMore) className.push("open");
      }
    }
  } else {
    textClassName.push("transparent");
  }
  textClassName.push('description-title font-weight-400')
  return (
    <div className={className.join(" ")} ref={ref}>
      {props.type === "type-1" ? (
        <div className="ds-mb-10">
          <Text className='description-title'>{"Description"}</Text>
        </div>
      ) : (
        <Icon icon="Subtract" size={20} className="ds-mr-10" />
      )}
      <Text
        className={textClassName.join(" ")}
      >
        {renderText()}
        {renderMore()}
      </Text>
    </div>
  );
};
MoleculeReadMore.defaultProps = {
  text: "",
  type: "type-1",
  sliceValue: 10,
};
export default MoleculeReadMore;
