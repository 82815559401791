import React from 'react';
import Switch from "react-switch";
import PropTypes from 'prop-types'

const SwitchInput = (props) => {
    return (
        <Switch
            onChange={props.onchangeHandle}
            checked={props.checked}
            offColor={props.offColor}
            onColor={props.onColor}
            onHandleColor={props.onHandleColor}
            uncheckedIcon={props.uncheckedIcon}
            checkedIcon={props.checkedIcon}
            height={props.height}
            width={props.width}
            handleDiameter={props.handleDiameter}
            boxShadow={props.boxShadow}
            activeBoxShadow={props.activeBoxShadow}
        />
    )
}
SwitchInput.defaultProps = {
    onchangeHandle: () => console.log(),
    offColor: "#8D8D8D",
    onColor: "#78d9ff",
    onHandleColor: "#ffffff",
    uncheckedIcon: false,
    checkedIcon: false,
    checked: false,
    height: 45,
    width: 74,
    handleDiameter: 37,
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.6)",
    activeBoxShadow: "0px 0px 1px 10px rgba(0, 0, 0, 0.2)",
};

SwitchInput.propTypes = {
    onchangeHandle: PropTypes.func,
    offColor: PropTypes.string,
    onColor: PropTypes.string,
    onHandleColor: PropTypes.string,
    uncheckedIcon: PropTypes.bool,
    checkedIcon: PropTypes.bool,
    checked: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    handleDiameter: PropTypes.number,
    boxShadow: PropTypes.string,
    activeBoxShadow: PropTypes.string,
};
export default SwitchInput