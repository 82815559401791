import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import PageHome from "../Pages/PageHome/PageHome";
import PageSignin from "../Pages/PageSignin/PageSignin";
import PageSignup from "../Pages/PageSignup/PageSignup";
import PageEvent from './../Pages/PageEvent/PageEvent';
import PageContactList from './../Pages/PageContactList/PageContactList';
import PageGuestList from './../Pages/PageGuestList/PageGuestList';
import PageProfile from './../Pages/PageProfile/PageProfile';
import PageMenu from './../Pages/PageMenu/PageMenu';
import PageEvents from './../Pages/PageEvents/PageEvents';
import PageWelcome from './../Pages/PageWelcome/PageWelcome';
import PageAddRelative from './../Pages/PageAddRelative/PageAddRelative';
import PageEventDetails from './../Pages/PageEventDetails/PageEventDetails';
import PublicRoute from './PublicRoute/PublicRoute';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import PageResetPassword from './../Pages/PageResetPassword/PageResetPassword';
const MainRoute = () => {
    return (
        <BrowserRouter >
            <Routes >
                <Route path="/" element={<PublicRoute component={<PageWelcome />} />} />
                <Route path="/signin" element={<PublicRoute component={<PageSignin />} />} />
                <Route path="/signup" element={<PublicRoute component={<PageSignup />} />} />
                <Route path="/home" element={<PrivateRoute component={<PageHome />} />} />
                <Route path="/event" element={<PublicRoute withToken component={<PageEvent />} />} />
                <Route path="/event/:id" element={<PrivateRoute component={<PageEvent />} />} />
                <Route path="/eventDetails/:id" element={<PublicRoute withToken component={<PageEventDetails />} />} />
                <Route path="/eventDetails" element={<PrivateRoute component={<PageEventDetails />} />} />
                <Route path="/contacts" element={<PrivateRoute component={<PageContactList />} />} />
                <Route path="/guestList" element={<PrivateRoute component={<PageGuestList />} />} />
                <Route path="/guestList/:id" element={<PrivateRoute component={<PageGuestList />} />} />
                <Route path="/menu" element={<PrivateRoute component={<PageMenu />} />} />
                <Route path="/profile" element={<PrivateRoute component={<PageProfile />} />} />
                <Route path="/events" element={<PrivateRoute component={<PageEvents />} />} />
                <Route path="/relative" element={<PublicRoute withToken component={<PageAddRelative />} />} />
                <Route path="/resetPassword/:token" element={<PublicRoute withToken component={<PageResetPassword />} />} />
            </Routes>
        </BrowserRouter>
    );
};
export default MainRoute;