import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { TemplateEventDetails } from "../../Templates";
import { GenericOrganismModal } from "../../Organisme";
import {
  apiGetMyResponse,
  apiGetEvent,
  apiUpdateReplyToEvent,
} from "../../Api/Events/events";
import { setEventId, setEventToReply } from "../../Redux/EventActions/EventActions";
import { formatDescription, onGetTime, share } from "../../common";
import moment from "moment";
import { atcb_action, atcb_init } from "add-to-calendar-button";

const PageEventDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { token } = useSelector((reduxState) => reduxState.auth);
  const { user } = useSelector((reduxState) => reduxState.auth);
  const { event } = useSelector((reduxState) => reduxState.event);
  const { state: locationState } = location;
  const allowRelative = event?.allowRelative;
  const [response, setResponse] = useState("");
  const [status, setStatus] = useState(null);
  const idEvent = params.id;
  const replyToken = location?.search.slice(7);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [actuelResponse, setActuelResponse] = useState("");
  const [isOpenPop, setIsOpenPop] = useState(false);
  const [isOpenReplyPopup, setIsOpenReplyPopup] = useState(false);
  const [isOpenPopUpReply, setIsOpenPopUpReply] = useState(false);
  const onClickNavigate = (path) => {
    navigate(path);
  };
  const onClosePopUpReply = () => {
    navigate(`/eventDetails/${params.id}`, { state: {}, replace: true });
    setIsOpenPopUpReply(false);
  };
  const pathName = window.location.href.slice(78)
  const onGetEventDetails = async () => {
    const res = await apiGetEvent(params.id, pathName);
    if (res?.statusCode === 200) {
      setActuelResponse(res?.data?.subsciptionStatus)
      setCurrentEvent(res?.data);
    } else {
      if (token) {
        navigate("/home");
      } else {
        navigate("/");
      }
    }
  };
  const onClickShare = () => {
    dispatch(setEventId(params.id));
    navigate(`/contacts`);
  };
  function onShowGuestList() {
    dispatch(setEventId(params.id));
    navigate(`/guestList/${params.id}`);
  }
  const onGetStatus = () => {
    switch (response) {
      case "Attending":
        return 1;
      case "Maybe":
        return 2;
      case "Not Attending":
        return 3;
      default:
        break;
    }
  };
  const onConfirmResponse = async () => {
    if (!token) {
      if (replyToken !== "") {
        const res = await apiUpdateReplyToEvent(
          JSON.stringify({ status: onGetStatus(), unique_token: replyToken })
        );
        if (res.statusCode === 200) {
          setStatus(res?.data?.status);
          setIsOpenPopUpReply(true);
          setIsOpenReplyPopup(false);
        }
      } else {
        if (response !== "") {
          dispatch(setEventToReply({
            event_id: idEvent,
            status: onGetStatus()
          }));
          navigate("/signup", {
            state: { replyEvent: onGetStatus(), eventId: idEvent, fromReplyEvent: true }
          });
        }
      }
    } else {
      const res = await apiGetMyResponse(
        JSON.stringify({
          event_id: params.id,
          status: onGetStatus()
        })
      );
      if (res.statusCode === 201) {
        setActuelResponse(response)
        setStatus(res?.data?.status);
        setIsOpenReplyPopup(false)
        setIsOpenPopUpReply(true);
      }
    }
  };
  const onSetTitlePopup = () => {
    switch (status) {
      case 1:
        return "You’ve successfully been added to the list.";
      case 2:
        return "You are on the maybe list";
      case 3:
        return "You’ve declined the invitation to this event.";
      default:
        break;
    }
  };
  const onShowButtonAddRelative = () => {
    switch (response) {
      case "Attending":
        return true;
      case "Maybe":
        return false;
      case "Not Attending":
        return false;
      default:
        break;
    }
  };
  const onClickSeeEventById = () => {
    if (locationState?.eventCreate && locationState?.eventId) {
      setIsOpenPop(false);
    }
    if (locationState?.idEventCreated) {
      setIsOpenPop(false);
    }
  };
  const onAddToMyAgenda = async () => {
    atcb_action({
      name: currentEvent?.label,
      location: currentEvent?.location,
      description: formatDescription(currentEvent?.description),
      startDate: moment(currentEvent?.start_date).format("YYYY-MM-DD"),
      endDate: moment(currentEvent?.end_date).format("YYYY-MM-DD"),
      startTime: moment(currentEvent?.start_date).format("HH:mm"),
      endTime: moment(currentEvent?.end_date).format("HH:mm"),
      options: [
        "Apple",
        "Google",
        "iCal",
        "Microsoft365",
        "Outlook.com",
        "Yahoo"
      ],
      timeZone: "Europe/Berlin"
    });
  };
  useEffect(() => {
    atcb_init;
    onGetEventDetails();
    if (locationState?.replyStatus) {
      setStatus(locationState?.replyStatus);
      setIsOpenPopUpReply(true);
    }
    if ((locationState?.eventCreate && locationState?.eventId) || locationState?.idEventCreated) {
      setIsOpenPop(true);
    }
  }, []);
  const getDescriptionShare = () => {
    const res = `Hi! You are invited to ${currentEvent?.user?.firstname
      }'s event. Date : ${moment(currentEvent.start_date).format(
        "MMMM DD, YYYY"
      )}. To see more info and reply please click on the link below.`;
    return res;
  };
  const onClickBackEvent = () => {
    if (token) {
      if (locationState?.fromEvents) {
        navigate("/events");
      } else {
        navigate("/home");
      }
    } else {
      navigate("/");
    }
  };
  const onShare = () => {
    share({
      id: locationState?.idEventCreated
        ? locationState?.idEventCreated
        : params.id,
      description: getDescriptionShare()
    });
  };
  return (
    <>
      <TemplateEventDetails
        user={user}
        actuelResponse={actuelResponse}
        currentEvent={currentEvent}
        locationState={locationState}
        onClickEdit={() =>
          navigate(`/event/${params.id}`, {
            state: { editEvent: true, idEventEdited: params.id }
          })
        }
        onClickShare={onClickShare}
        onClickBackEvent={onClickBackEvent}
        onShowGuestList={() => onShowGuestList()}
        onOpenReplyPopup={() => setIsOpenReplyPopup(true)}
        onShare={() => onShare()}
        addAgenda={() => onAddToMyAgenda()}
      />
      {isOpenPop && <GenericOrganismModal
        title={"YOU’VE SUCCESSFULLY CREATED AN EVENT."}
        showModal={isOpenPop}
        onCloseModal={() => setIsOpenPop(false)}
        eventCreated={true}
        onClickFirstBtn={() => onClickSeeEventById()}
        moldeculeClassName={"md-content"}
        iconDone={true}
        onClickThirdBtn={() => onClickShare()}
        onClickFourthBtn={() => onShare()}
        onClickSecondBtn={() => onAddToMyAgenda()}
        addAgenda={true}
        sharewithList={true}
        showAddRelative={true}
      />}
      {isOpenReplyPopup && <GenericOrganismModal
        showModal={isOpenReplyPopup}
        onCloseModal={() => setIsOpenReplyPopup(false)}
        eventCreated={false}
        text={""}
        title1={"Reply"}
        btnText={"CONFIRM"}
        moldeculeClassName={"modale-reply"}
        resetPassword={false}
        iconDone={false}
        reply={true}
        onClickFirstBtn={onConfirmResponse}
        onClickAttending={() => setResponse("Attending")}
        onClickMaybe={() => setResponse("Maybe")}
        onClickNotAttending={() => setResponse("Not Attending")}
        response={response}
        showAddRelative={true}
      />}
      {isOpenPopUpReply && <GenericOrganismModal
        title={onSetTitlePopup()}
        text={
          response === "Not Attending" && "Hope to see you at the next one !"
        }
        showModal={isOpenPopUpReply}
        onCloseModal={onClosePopUpReply}
        eventCreated={true}
        onClickFirstBtn={() =>
          navigate("/relative", {
            state: {
              eventId: params.id,
              unique_token: locationState?.uniqueToken
            }
          })
        }
        onClickFourthBtn={() =>
          share({ id: params.id, description: getDescriptionShare() })
        }
        onClickSecondBtn={() => onAddToMyAgenda()}
        moldeculeClassName={"md-content"}
        iconDone={true}
        resetPassword={false}
        btnText={"ADD A RELATIVE"}
        backToEvent={response === "Attending" ? true : false}
        icon={"Plus"}
        addAgenda={response !== "Attending" ? true : false}
        onClickBackPopUp={onClosePopUpReply}
        showAddRelative={currentEvent?.is_allowed_relative_add}
      />
      }
    </>
  );
};

export default PageEventDetails;
