import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { GenericOrganismModal } from '../../Organisme';
import { TemplateAddRelative } from '../../Templates';
import { validateEmail } from './../../Validation/index';
import { useSelector } from 'react-redux';
import { defineState } from '../../common';
import { apiAddRelative, apiAddRelativeGuest } from '../../Api/Relative/Relative';
import { error } from '../../common/validationMessage';

const PageAddRelative = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const user = useSelector(state => state.auth.user)
    const [isBtnDisabled, setIsBtnDisabled] = useState(false)
    const [isOpenPopup, setIsOpenPopup] = useState(false)
    const [name, setName] = useState("")
    const { state: locationState } = location;
    const [state, setState] = useState({
        firstName: { ...defineState('') },
        lastName: { ...defineState('') },
        email: { ...defineState('') },
    })
    const onBlur = (key, value) => {
        const aux = { ...state }
        const isEmpty = value === "";
        aux[key].value = value;
        aux[key].isInvalid = false
        aux[key].errormsg = null
        if (key === "email") {
            const isMail = validateEmail(value);
            if (!isMail || isEmpty) {
                aux[key].isInvalid = true
                if (isEmpty) {
                    aux[key].errormsg = error.emptyMail
                } else {
                    aux[key].errormsg = error.checkMail
                }
            }
        } else {
            if (isEmpty) {
                aux[key].isInvalid = true
                if (key === "firstName") {
                    aux[key].errormsg = error.emptyFirstName
                } else {
                    aux[key].errormsg = error.emptyLastName
                }
            }
        }
        setState(aux)
    }
    const onChange = (key, value) => {
        const aux = { ...state }
        aux[key].value = value;
        aux[key].isInvalid = false
        setState(aux)
    }
    const onAddRelative = async () => {
        setIsBtnDisabled(true)
        if (locationState?.unique_token) {
            if ((!state?.firstName.isInvalid)
                && (!state?.lastName.isInvalid)
                && (!state?.email.isInvalid)
                && (state?.firstName.value !== '')
                && (state?.lastName.value !== '')
                && (state?.email.value !== '')
                && validateEmail(state.email.value)
            ) {
                const response = await apiAddRelativeGuest(JSON.stringify({
                    event_id: locationState.eventId,
                    unique_token: locationState?.unique_token,
                    firstname: state?.firstName.value,
                    lastname: state?.lastName.value,
                    email: state?.email.value,
                }))
                if (response?.statusCode === 200) {
                    setIsOpenPopup(true)
                } else {
                    let errors = {}
                    errors.firstName = error.emptyFirstName
                    errors.lastName = error.emptyLastName
                    errors.email = error.checkMail
                    onChangeErrorMsg(errors)
                }
            } else {
                let errors = {}
                errors.firstName = error.emptyFirstName
                errors.lastName = error.emptyLastName
                errors.email = error.emptyMail
                onChangeErrorMsg(errors)
            }
        } else {
            if ((!state?.firstName.isInvalid)
                && (!state?.lastName.isInvalid)
                && (!state?.email.isInvalid)
                && (state?.firstName.value !== '')
                && (state?.lastName.value !== '')
                && (state?.email.value !== '')
                && validateEmail(state.email.value)
            ) {
                const response = await apiAddRelative(JSON.stringify({
                    event_id: locationState?.eventId,
                    firstname: state?.firstName.value,
                    lastname: state?.lastName.value,
                    email: state?.email.value,
                }))
                if (response?.statusCode === 200) {
                    onChangeErrorMsg({})
                    setIsOpenPopup(true)
                } else {
                    let errors = {}
                    errors.firstName = error.emptyFirstName
                    errors.lastName = error.emptyLastName
                    errors.email = error.checkMail
                    onChangeErrorMsg(errors)
                }
            } else {
                let errors = {}
                errors.firstName = error.emptyFirstName
                errors.lastName = error.emptyLastName
                errors.email = error.emptyMail
                onChangeErrorMsg(errors)
            }
        }
        setIsBtnDisabled(false)
    }
    const onChangeErrorMsg = ({ email = null, firstName = null, lastName = null }) => {
        setState({
            ...state,
            firstName: {
                ...state.firstName,
                isInvalid: firstName ? true : false,
                errormsg: firstName
            },
            lastName: {
                ...state.lastName,
                isInvalid: lastName ? true : false,
                errormsg: lastName
            },
            email: {
                ...state.email,
                isInvalid: email ? true : false,
                errormsg: email
            },
        })
    }
    useEffect(() => {
        setName(state?.firstName?.value)
    }, [state?.firstName?.value])

    return (
        <>
            <TemplateAddRelative
                onClickBackEvent={() => navigate(`/eventDetails/${locationState.eventId}`)}
                onChange={onChange}
                onBlur={onBlur}
                state={state}
                onAddRelative={onAddRelative}
                isBtnDisabled={isBtnDisabled}
            />
            <GenericOrganismModal
                showModal={isOpenPopup}
                onCloseModal={() => navigate(`/eventDetails/${locationState.eventId}`)}
                eventCreated={false}
                text={""}
                title={`You added ${name} to the event  🎉`}
                title1={""}
                btnText={"BACK TO EVENT"}
                moldeculeClassName={"modale-reply"}
                resetPassword={false}
                iconDone={true}
                onClickFirstBtn={() => navigate(`/eventDetails/${locationState.eventId}`)}
                showAddRelative={true}
            />
        </>

    )
}

export default PageAddRelative