import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Text } from '../../Atoms';
import { getComponentProps, LOGO, render3Cercles } from '../../common';
import { MoleculeInput, ButtonWithIcon } from '../../Molecules';
import { scrollToTop } from '../../Redux/authActions/authActions';

const TemplateSignin = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(scrollToTop())
    }, [])
    return (
        <div className="ds-pr-20 ds-pl-20 ds-pb-50 ds-w-100 ds-h-100 relative flex-col space-between">
            {render3Cercles()}
            <div className="ds-pt-100 center flex-col">
                <img src={LOGO} className="logo flex justify-center ds-hp-72 z-index-1 ds-w-100" />
                <Text type="type-5" className="z-index-1">The easiest way to plan an event</Text>
            </div>
            <div className="ds-w-100 flex-gr-1 flex-col center">
                <div className="z-index-1 ds-w-100">
                    <MoleculeInput
                        label="Email"
                        className="z-index-1"
                        onChange={(e) => props.onChange("email", e.target.value)}
                        onBlur={(e) => props.onBlur("email", e.target.value)}
                        {...getComponentProps(props?.state?.email)}
                        type="email"
                    />
                </div>
                <div className="ds-mt-20 z-index-1 ds-w-100">
                    <MoleculeInput
                        label="password"
                        onChange={(e) => props.onChange("password", e.target.value)}
                        onBlur={(e) => props.onBlur("password", e.target.value)}
                        {...getComponentProps(props?.state?.password)}
                        isPassword
                    />
                </div>
                <div className="ds-mt-11 pointer ds-w-100">
                    <Text type="type-grey1" onClick={() => props.onForgotPassword()}>Forgot your password ?</Text>
                </div>
                <div className="ds-pt-31 center flex ds-w-100">
                    <Text type="type-12">{"Don’t have an account? "}<Text type="first-text type-24" className={"pointer"} onClick={() => props.onClickSignUp()}> Sign up</Text></Text>
                </div>
            </div>
            <div className='ds-w-100'>
                <ButtonWithIcon
                    text="CONFIRM"
                    textType="type-black"
                    className="ds-w-100"
                    onClick={() => props.onSubmit()}
                    isLoading={props.isLoading}
                />
            </div>
        </div>
    )
}

export default TemplateSignin