import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { TemplateMenu } from '../../Templates';
import { useDispatch, useSelector } from 'react-redux';
import { apiDeleteContact, apiGetContacts } from '../../Api/Contact/Contact';
import { resetToken } from '../../Redux/authActions/authActions';

const PageMenu = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [seeContacts, setSeeContacts] = useState(false);
  const [contactsList, setContactsList] = useState([])

  const onGetListContacts = async () => {
    const response = await apiGetContacts();
    if (response?.statusCode === 200) {
      setContactsList(response?.data)
    }
  }
  const onDeleteContact = async (i) => {
    const response = await apiDeleteContact(contactsList[i].id)
    if (response?.statusCode === 200) {
      onGetListContacts()
    }
  }
  useEffect(() => {
    if (seeContacts) {
      onGetListContacts()
    }
  }, [seeContacts])

  return (
    <TemplateMenu
      onClickProfile={() => navigate("/profile")}
      onClickCloseMenu={() => navigate("/home")}
      onClickSeeMyContacts={() => setSeeContacts(true)}
      onClickBackToMenu={() => setSeeContacts(false)}
      seeContacts={seeContacts}
      contactsList={contactsList}
      onDeleteContact={onDeleteContact}
      onClickMyEvents={() => navigate("/events")}
      onLogOut={() => dispatch(resetToken())}
    />
  )
}

export default PageMenu