import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export default function PublicRoute(props) {
  const token = useSelector(state => state.auth.token);
  if (!token || token && props.withToken) {
    return (
      <div className='ds-w-100 ds-h-100 '>
        {props.component}
      </div>
    );
  }
  return <Navigate to='/home' replace />;
}
