import React, { useEffect } from 'react';
import { Text } from '../../Atoms';
import { MoleculeInput, ButtonWithIcon } from '../../Molecules';
import { getComponentProps, renderCercleType2, UNION } from '../../common';
import { error } from '../../common/validationMessage';
import { scrollToTop } from '../../Redux/authActions/authActions';
import { useDispatch } from 'react-redux';

const TemplateSignup = (props) => {
    const dispatch = useDispatch()
    const getTextSignup = () => {
        if (props.locationState?.fromEvent) {
            return "Create an account to see your event";
        } else if (props.locationState?.replyEvent) {
            return "Welcome ! Introduce yourself to reply";
        } else {
            return "Welcome !"
        }
    }
    useEffect(() => {
        dispatch(scrollToTop())
    }, [])
    return (
        <div className='ds-w-100 ds-h-100 relative'>
            {renderCercleType2()}
            <div className="ds-pr-20 ds-pl-20 ds-pb-50">
                <div className="cercle-greay absolute z-index-3"></div>
                <div className="logo-visitr ds-w-100 z-index-100">
                    <img src={UNION} className={"logo-reply z-index-100 ds-pt-15"} />
                </div>
                <div className="ds-mt-10 flex center ds-w-100">
                    <Text type={!props?.locationState?.fromEvent ? "type-0" : "type-4"} className="z-index-100">{getTextSignup()}</Text>
                </div>
                <div className="ds-mt-26 z-index-1 ds-w-100">
                    <MoleculeInput
                        label="First name*"
                        onChange={(e) => props.onChange("firstName", e.target.value)}
                        onBlur={(e) => props.onBlur("firstName", e.target.value)}
                        {...getComponentProps(props?.state?.firstName)}
                        errormsg={error.emptyFirstName}
                    />
                    <div className="ds-mt-20 ds-w-100 z-index-1">
                        <MoleculeInput
                            label="Last name*"
                            onChange={(e) => props.onChange("lastName", e.target.value)}
                            onBlur={(e) => props.onBlur("lastName", e.target.value)}
                            {...getComponentProps(props?.state?.lastName)}
                            errormsg={error.emptyLastName}
                        />
                    </div>
                    <div className="ds-mt-20 ds-w-100 z-index-1">
                        <MoleculeInput
                            label="Email*"
                            onChange={(e) => props.onChange("email", e.target.value)}
                            onBlur={(e) => props.onBlur("email", e.target.value)}
                            {...getComponentProps(props?.state?.email)}
                            type="email"
                        />
                    </div>
                    {!props?.locationState?.replyEvent && <div className="ds-mt-20 ds-w-100 z-index-1">
                        <MoleculeInput
                            isPassword
                            label="Password*"
                            onChange={(e) => props.onChange("password", e.target.value)}
                            onBlur={(e) => props.onBlur("password", e.target.value)}
                            {...getComponentProps(props?.state?.password)}
                        />
                    </div>}
                    {(!props?.locationState?.replyEvent) && <div
                        className={props.isShow ? 'display-none' : 'ds-mt-20 ds-w-100 z-index-1'}>
                        <MoleculeInput
                            isPassword
                            label="Repeat password*"
                            onChange={(e) => props.onChange("repeatPassword", e.target.value)}
                            onBlur={(e) => props.onBlur("repeatPassword", e.target.value)}
                            {...getComponentProps(props?.state?.repeatPassword)}
                        />
                    </div>}
                    {(!props?.locationState?.replyEvent) && <div
                        className={props.isShow ? 'display-none' : 'ds-mt-20 ds-w-100 z-index-1'}>
                        <MoleculeInput
                            label="Mobile phone"
                            onChange={(e) => props.onChange("phone", e.target.value, 5)}
                            onBlur={(e) => props.onBlur("phone", e.target.value, 5)}
                            {...getComponentProps(props?.state?.phone)}
                            type="tel"
                        />
                    </div>}
                    <div className="ds-mt-31 flex center ds-mb-23 ds-w-100">
                        <Text type="type-12">Already have an account?<Text type="first-text type-24" className={"pointer"} onClick={() => props.onClickSignIn()}> Sign in</Text></Text>
                    </div>
                    <div className='ds-w-100'>
                        <ButtonWithIcon
                            text="CONFIRM"
                            textType="type-black"
                            onClick={props.onSubmit}
                            isLoading={props.isLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TemplateSignup