import PropTypes from "prop-types";
import React from "react";
import { Icon, Text } from "../../Atoms";
import { getComponentProps, getShareText } from "../../common";
import { ButtonWithIcon, MoleculeInput, MoleculeModal } from "../../Molecules";
import { error } from "./../../common/validationMessage";
const GenericOrganismModal = (props) => {
  return (
    <MoleculeModal
      show={props.showModal}
      onHide={props.onCloseModal}
      onHideWithReset={props.onCloseModal}
      contentClassName={`default-modal  ds-w-100 ${props.moldeculeClassName}`}
      dialogClassName="default-modal ds-w-100"
      title1={props.title1}
    >
      <div className={props.iconDone ? "ds-pt-45 " : "ds-pt-20"}>
        {props.iconDone && (
          <Icon icon="check" size="70" className="ds-hp-70 pop-icon" />
        )}
        {props.title && (
          <div className="ds-pt-20">
            <Text type="type-25">{props.title}</Text>
          </div>
        )}
        {props.text && (
          <Text
            className={
              props.iconDone
                ? "ds-pt-20 ds-pb-20 "
                : "ds-pt-20 flex center ds-pb-20"
            }
          >
            {props.text}
          </Text>
        )}
        {props.resetPassword && (
          <div className="ds-w-100">
            <MoleculeInput
              label={"Email"}
              value={props.inputValue}
              onChange={props.onChangeInput}
              onBlur={props.onBlurInput}
              {...getComponentProps(props.inputValue)}
              type="email"
            />
          </div>
        )}
        {props.showImg &&
          <div className="ds-w-100 flex flex-col center" style={{marginBottom:"-36px"}}>
            <div className="profie-img border-radius-50 relative flex center" >
              <Text type={"upper-case"} className="absolute">{props.name}</Text>
            </div>
            <Text type="type-16" className={"ds-mt-8"}>{props.pseudo}</Text>
          </div>}
        {props.reply && (
          <div>
            <ButtonWithIcon
              className={`type-3 ds-mt-60 ${props.response === "Attending" ? "active" : null
                }`}
              text={"ATTENDING"}
              onClick={props.onClickAttending}
            />
            <ButtonWithIcon
              className={`type-3 ds-mt-10 ${props.response === "Maybe" ? "active" : null
                }`}
              text={"MAYBE"}
              onClick={props.onClickMaybe}
            />
            <ButtonWithIcon
              className={`type-3 ds-mt-10 ${props.response === "Not Attending" ? "active" : null
                }`}
              text={"NOT ATTENDING"}
              onClick={props.onClickNotAttending}
            />
          </div>
        )}
        {props.showAddRelative && (
          <div className={`${props.reply} ? ds-pt-20 : ds-pt-50`}>
            <ButtonWithIcon
              className="default-btn"
              text={props.btnText}
              textType={"type-black"}
              icon={props.icon}
              iconSize={30}
              onClick={props.onClickFirstBtn}
            />
          </div>
        )}

        {props.eventCreated && (
          <div className={`flex-col ds-w-100`}>
            {props.addAgenda && (
              <div className="ds-pt-10">
                <ButtonWithIcon
                  text="ADD TO MY AGENDA"
                  className="type-3"
                  icon="Plus-1"
                  iconSize={28}
                  iconClassName="third-icon"
                  onClick={props.onClickSecondBtn}
                />
              </div>
            )}
            {props.sharewithList && (
              <div className="ds-pt-10">
                <ButtonWithIcon
                  text="SHARE WITH MY CONTACT LIST"
                  className="type-3"
                  icon="new-user"
                  iconSize={20}
                  iconClassName="fill-white second-icon"
                  onClick={props.onClickThirdBtn}
                  isShare
                />
              </div>
            )}
            <div className={`ds-pt-10`}>
              <ButtonWithIcon
                text={getShareText()}
                className="type-3"
                icon="link"
                iconSize={33}
                iconClassName="first-icons"
                onClick={props.onClickFourthBtn}
                isShare
              />
            </div>
          </div>
        )}
        {props.backToEvent && (
          <ButtonWithIcon
            text="BACK TO EVENT"
            className="type-3 ds-mt-10"
            onClick={props.onClickBackPopUp}
          />
        )}
      </div>
    </MoleculeModal>
  );
};

export default GenericOrganismModal;

GenericOrganismModal.propTypes = {
  onCloseRappelModal: PropTypes.any,
  onConfirmBtnClick: PropTypes.any,
  showModal: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.any,
  title1: PropTypes.any,
  inputValue: PropTypes.any,
  btnText: PropTypes.any,
  onCloseModal: PropTypes.func,
  onClickFirstBtn: PropTypes.func,
  onClickSecondBtn: PropTypes.func,
  onClickThirdBtn: PropTypes.func,
  onClickFourthBtn: PropTypes.func,
  onChangeInput: PropTypes.func,
  onClickAttending: PropTypes.func,
  onClickMaybe: PropTypes.func,
  onClickNotAttending: PropTypes.func,
  onClickBackPopUp: PropTypes.func,
  eventCreated: PropTypes.bool,
  moldeculeClassName: PropTypes.any,
  iconDone: PropTypes.bool,
  resetPassword: PropTypes.bool,
  reply: PropTypes.bool,
  sharewithList: PropTypes.bool,
  backToEvent: PropTypes.bool,
  addAgenda: PropTypes.bool,
  showAddRelative: PropTypes.bool,
  icon: PropTypes.any,
  showImg: PropTypes.bool,
};
GenericOrganismModal.defaultProps = {
  title: "",
  title1: "",
  inputValue: "",
  text: "Share it with your friends !",
  btnText: "SEE EVENT",
  eventCreated: false,
  onCloseModal: () => console.log(),
  onClickFirstBtn: () => console.log(),
  onClickSecondBtn: () => console.log(),
  onClickThirdBtn: () => console.log(),
  onClickFourthBtn: () => console.log(),
  onClickAttending: () => console.log(),
  onClickBackPopUp: () => console.log(),
  onClickMaybe: () => console.log(),
  onClickNotAttending: () => console.log(),
  onChangeInput: () => console.log(),
  moldeculeClassName: "default-modal",
  iconDone: false,
  resetPassword: false,
  reply: false,
  sharewithList: false,
  backToEvent: false,
  addAgenda: false,
  showAddRelative: false,
  icon: null,
  showImg: false,
};
