import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import Geocode from "react-geocode";
import MapStyle from '../../MapStyle/MapStyle';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../../Config/Config';
import { Icon, Text } from '../../Atoms';
import Mark from "../../Assets/Icons/Location.svg"

const apiKey = REACT_APP_GOOGLE_MAPS_API_KEY
Geocode.setApiKey(apiKey);
Geocode.setLanguage("fr");
Geocode.setRegion("fr");
Geocode.enableDebug();


const OrganismMaps = (props) => {
    const [lat, setLat] = useState(null)
    const [lng, setLng] = useState(null)
    useEffect(() => {
        if (props.address) {
            getPosition()
        }
    }, [props.address])
    const getPosition = () => {
        Geocode.fromAddress(props.address).then(
            (response) => {
                const { lat: lt, lng: ln } = response.results[0].geometry.location;
                setLat(lt)
                setLng(ln)
            },
        );
    }
    const center = {
        lat: lat ? lat : props.lat,
        lng: lng ? lng : props.lng,
    }
    return (
        <div className='loc-box ds-pt-10 ds-pl-20 ds-pr-20 ds-pb-20 ds-mt-20 ds-w-100' >
            <div className='ds-pb-12 flex'>
                <img src={Mark} height='20px' width={'20px'} className="ds-mr-10" />
                <Text type='type-5' style={{ fontWeight: "400px" }}>{props.address}</Text>
            </div>
            <div className='ds-w-100 '>
                {lng !== null && lng !== null && <Map
                    google={props.google}
                    zoom={15}
                    initialCenter={center}
                    center={center}
                    styles={MapStyle}
                    className={"map-card"}
                >
                    {lat && lng && <Marker
                        position={{ lat, lng }} />}
                </Map>}
            </div>
        </div>

    )
}

export default GoogleApiWrapper({
    apiKey: apiKey
})(OrganismMaps)


OrganismMaps.propTypes = {
    address: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    className: PropTypes.string,

};
OrganismMaps.defaultProps = {
    address: null,
    lat: 34.7498416,
    lng: 10.7436195,
};
