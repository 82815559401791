import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MetaDecoratorHelmet } from "./Molecules";
import { resetEventId } from "./Redux/EventActions/EventActions";
import MainRoute from "./Routes/MainRoute";

function App() {
  const { token, scroll } = useSelector((state) => state.auth);
  const ref = useRef();
  const [count, setCount] = useState(0)
  const dispatch = useDispatch();
  useEffect(() => {
    if (!token && count) {
      dispatch(resetEventId());
    }
    if (!token && count === 0) {
      setTimeout(() => {
        setCount(count + 1)
      }, 1000);
    }
  }, [token, count]);
  
  useEffect(() => {
    ref.current.scrollTo({ top: 0 });
  }, [scroll]);
 
  return (
    <>
    {/* <MetaDecoratorHelmet 
    title="REEPLY"
    description="HII"
    /> */}
    <div
      className="ds-w-100 ds-h-100 relative flex justify-center"
      style={{ height: window.innerHeight }}
    >
      <div className="ds-h-100 ds-w-100 container-pages" ref={ref}>
        <MainRoute />
      </div>
    </div>
    </>
  );
}

export default App;
