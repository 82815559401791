import {Helmet}  from 'react-helmet'
import React from "react";
import PropTypes from "prop-types";

const MetaDecoratorHelmet =({title, description, imageUrl, imageAlt, url})=> {
    return (
      <Helmet>
          <title>{"REEPLY"}</title>
          <meta name="description" content={description}/>
          <meta property="og:title" content={title}/>
          <meta property="og:type" content="article" />
          <meta property="og:image" content={imageUrl}/>
          <meta property="og:url" content={url}/>
          <meta property="og:type" content="website" />
          {/* <meta name="twitter:card" content="summary_large_image"/> */}
  
          <meta property="og:description" content={description}/>
          {/* <meta property="og:site_name" content="European Travel, Inc."/> */}
          {/* <meta name="twitter:image:alt" content={imageAlt}/> */}
      </Helmet>
    )
   }


MetaDecoratorHelmet.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default MetaDecoratorHelmet;