import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "../../Atoms";
import { render2Cercles } from "../../common";
import { ButtonWithIcon, Header } from "../../Molecules";
import { Card, Carousel } from "../../Organisme";
import { scrollToTop } from "../../Redux/authActions/authActions";
const SUBTITLECLASSNAME = "ds-pt-60 ds-pb-20 ds-px-20";
const TemplateHome = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const renderCarousel = ({ list, fn, title, className }) => {
    return (
      <>
        <div className={className + " ds-px-20"}>
          <Text type="type-4">{title}</Text>
        </div>
        <Carousel list={list} fn={fn} />
      </>
    );
  };
  useEffect(() => {
    dispatch(scrollToTop());
  }, []);
  return (
    <div className="ds-w-100 ds-h-100 relative">
      {render2Cercles()}
      <div className="ds-px-20">
        <Header
          menu="menu-burger-1"
          type="type1"
          onClickMenu={() => props.onOpenMenu()}
        />
      </div>
      <div className="ds-py-50 ds-w-100">
        <div className="ds-px-20">
          <Text type="type-0">{"Welcome back, " + user?.firstname + " ."}</Text>
        </div>
        <div className="ds-px-20 ds-pt-26 ds-w-100">
          <ButtonWithIcon
            text="CREATE EVENT"
            textType="type-black"
            icon="Plus"
            iconSize={28}
            iconClassName="first-icon ds-mr-20"
            onClick={() => props.onCreateEvent()}
          />
        </div>
        {props.upcommingEvents && props.upcommingEvents?.length === 0 ? (
          <>
            <div className={SUBTITLECLASSNAME}>
              <Text type="type-4">Upcoming events</Text>
              <div>
                <Text type="type-5">Your have no upcoming event.</Text>
              </div>
            </div>
            <div className="ds-px-20 ">
              <Card
                type="type1"
                classNameCard={
                  "flex-col align-start ds-pb-16 flex-grow card-type2"
                }
                onCreateEvent={() => props.onCreateEvent()}
              />
            </div>
          </>
        ) : null}
        {props.upcommingEvents?.length !== 0 ? (
          <>
            {renderCarousel({
              list: props.upcommingEvents,
              fn: props.goToEvent,
              className: SUBTITLECLASSNAME,
              title: "Upcoming events",
            })}
          </>
        ) : null}
        {props.endedEvents?.length !== 0 ? (
          <>
            <div className="ds-h-50 ds-w-100"></div>
            {renderCarousel({
              list: props.endedEvents,
              fn: props.goToEventEnded,
              title: "Past events",
              className: "ds-mt-50 ds-mb-20",
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TemplateHome;
