export function validateSpace(value) {
  if (!value.replace(/\s/g, "")?.length) {
    return false;
  }
  return true;
}
export function validateNull(value) {
  if (value !== "") {
    return true;
  }
  return false;
}
export function validateEmail(value) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}
export function validNumber(value) {
  const re = /^[0-9]+$/;
  return (re.test(value) || value?.indexOf(" ") !== -1) && value.indexOf('*') === -1 && value.indexOf('/') === -1 && value.indexOf('+') === -1;
}

export function validChar(value) {
  const re = /^[aA-zZ\s]+$/
  return re.test(value);
}
