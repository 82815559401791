import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Text } from '../../Atoms';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setCopyLink } from '../../Redux/EventActions/EventActions';
import { Spinner } from 'react-bootstrap';

ButtonWithIcon.defaultProps = {
    text: null,
    ClassName: null,
    iconClassName: null,
    disabled: false,
    onClick: null,
    style: null,
    icon: '',
    textType: 'default-text',
    iconSize: 18,
    isLoading: false,
};
ButtonWithIcon.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.any,
    style: PropTypes.any,
    icon: PropTypes.string,
    textType: PropTypes.string,
    iconSize: PropTypes.any,
    isLoading: PropTypes.bool,
};

function ButtonWithIcon(props) {
    const { copyNotification } = useSelector(state => state.event)
    const dispatch = useDispatch()
    useEffect(() => {
        if (copyNotification && props.isShare) {
            setTimeout(() => {
                dispatch(setCopyLink(false));
            }, 1000);
        }
    }, [copyNotification])
    const iconClassName = []
    if (props.text) {
        iconClassName.push("ds-mr-14")
    }
    if (props.iconClassName) {
        iconClassName.push(props.iconClassName)
    }
    return (
        <div className='ds-w-100 relative' >
            {props.isShare && copyNotification && <div className='absolute ds-w-100 flex center'
                style={{
                    top: "-50px",
                }}>
                <div
                    className='ds-px-20 ds-py-10'
                    style={{
                        background: "white",
                        color: "#161d1f",
                        width: "max-content",
                        borderRadius: "10px"
                    }}>
                    {copyNotification}
                </div>
            </div>
            }
            <Button
                className={props.className}
                text={props.text}
                disabled={props.isLoading ? true : props.disabled}
                onClick={props.onClick}
                style={props.style}
                icon={props.icon}
            >
                {props.isLoading ?
                    <Spinner animation="border" variant="primary" />
                    :
                    <>
                        {props.icon && <Icon icon={props.icon} size={props.iconSize} className={iconClassName.join(' ')} />}
                        {props.text && <Text type={props.textType}>{props.text}</Text>}
                    </>
                }
            </Button>
        </div>
    )
}

export default ButtonWithIcon