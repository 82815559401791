import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TemplateHome } from "../../Templates";
import { apiCreateEvent, apiGetEvents, apiGetMyResponse } from "../../Api/Events/events";
import { useDispatch, useSelector } from "react-redux";
import { deleteEvent, deleteEventToReplay, resetEvent } from "../../Redux/EventActions/EventActions";
import { onSortEvent } from "../../common";

const PageHome = () => {
  const location = useLocation();
  const { state: locationState } = location;
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const { event, eventToReply } = useSelector((reduxState) => reduxState.event);
  const auth = useSelector((reduxState) => reduxState.auth);
  const [upcommingEvents, setUpcommingEvents] = useState(null);
  const [endedEvents, setEndedEvents] = useState([]);
  const navigate = useNavigate();
  const onClickMyEvents = () => {
    setOpenMenu(true);
  };
  const onClickNavigate = (path) => {
    navigate(path);
  };
  const onClickMyContacts = () => {
    setOpenMenu(true);
  };


  const onGetUpcommingEvents = async () => {
    const response = await apiGetEvents();
    if (
      response.statusCode === 200 &&
      response?.data?.upcomingEvents &&
      response?.data?.endedEvents
    ) {
      const sortedAscendingDate= onSortEvent(response?.data?.upcomingEvents, true)
      const sortedDescendingDate= onSortEvent(response?.data?.endedEvents, false)
      setUpcommingEvents(sortedAscendingDate);
      setEndedEvents(sortedDescendingDate);
    }
 
  };
  const goToEvent = (index, key = "endedEvents") => {
    const list = key === "endedEvents" ? endedEvents : upcommingEvents;
    const id = list[index].id;
    if (id) {
      navigate(`/eventDetails/${id}`);
    }
  };
  useEffect(() => {
    onCallApis()
  }, []);
  const onCallApis = async () => {
    const id = await updateEvents()
    if (id) {
      navigate(`/eventDetails/${id}`);
    } else {
      await onGetUpcommingEvents();

    }
  }
  const updateEvents = async () => {
    let id = null
    if (eventToReply?.length) {
      for (let index = 0; index < eventToReply?.length; index++) {
        const res = await apiGetMyResponse(
          JSON.stringify({
            event_id: eventToReply[index].event_id,
            status: eventToReply[index].status,
          })
        );
        if (res.statusCode === 201 || res.statusCode === 200) {
          id = eventToReply[index].event_id
          dispatch(deleteEventToReplay(index));
        }
      }
    }
    if (event?.length) {
      for (let index = 0; index < event?.length; index++) {
        const response = await apiCreateEvent(event[index]);
        if (response.statusCode === 201) {
          id = response.data.id
          dispatch(deleteEvent(index));
        }
      }
    }
    return id
  }
  return (
    <TemplateHome
      upcommingEvents={upcommingEvents}
      endedEvents={endedEvents}
      onCreateEvent={() => onClickNavigate("/event")}
      goToEvent={(i) => goToEvent(i, "upcommingEvents")}
      goToEventEnded={(i) => goToEvent(i)}
      onOpenMenu={() => onClickNavigate("/menu")}
      openMenu={openMenu}
      onClickMyEvents={onClickMyEvents}
      onClickMyContacts={onClickMyContacts}
    />
  );
};

export default PageHome;
