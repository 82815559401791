import PropTypes from "prop-types";
import React from "react";
import Form from 'react-bootstrap/Form';

const Input = React.forwardRef((props, ref) => {
  const className = ['default-input']
  if (props.isInvalid) { className.push('invalid') }
  if (props.isValid) { className.push('valid') }
  if (props.className) { className.push(props.className) }
  const renderType = () => {
    if (props.isPassword) {
      return "password"
    }
    return props.type
  }
  return (
    <Form.Control
      ref={ref}
      type={renderType()}
      className={className.join(' ')}
      style={props.style}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onClick={props.onClick}
      placeholder={props.placeholder}
      disabled={props.disabled}
    />
  );
});

export default Input;

Input.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.any,
  id: PropTypes.string,
  isPassword: PropTypes.bool,
};
Input.defaultProps = {
  value: "",
  type: "text",
  className: null,
  isInvalid: false,
  isValid: false,
  onChange: () => console.log(),
  placeholder: null,
  style: null,
  disabled: false,
  id: null,
  isPassword: false,
};
