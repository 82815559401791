export const setUser = userObj => {
  return {
    type: 'SET_CURRENT_USER',
    value: userObj
  };
};
export const setToken = userObj => {
  return {
    type: 'SET_TOKEN',
    value: userObj
  };
};
export const resetToken = () => {
  return {
    type: 'RESET_TOKEN',
  };
}
export const scrollToTop = () => {
  return {
    type: "SET_SCROOL"
  }
}