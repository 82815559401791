import React from 'react'
import { Text } from '../../Atoms'
import { PropTypes } from 'prop-types';

const MoleculeStatus = (props) => {
    const setStatusColor = () => {
        switch (props.status) {
            case "CANCELLED":
                return 'ds-bg-purple';
            case "UPCOMING":
                return 'ds-bg-pink';
            case "RESCHEDULED":
                return 'ds-bg-bluesky';
            case "ENDED":
                return 'ds-bg-grey';
            default:
                break;
        }
    }
    return (
        <div className={`flex center ${props.className} ${setStatusColor()}`}>
            <Text type="type-17">{props.status}</Text>
        </div>
    )
}

export default MoleculeStatus

MoleculeStatus.defaultProps = {
    status: null,
    className: 'status'
};

MoleculeStatus.propTypes = {
    status: PropTypes.string,
    className: PropTypes.string,
};