import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../Atoms'
const StatusList = (props) => {
    const activeItemCount = (key) => {
        if (props.counts?.countAll) {
            switch (key) {
                case 0:
                    return props.counts?.countAll
                case 1:
                    return props.counts?.countAttending
                case 2:
                    return props.counts?.countMaybe
                case 3:
                    return props.counts?.countNotAttending
                default:
                    break;
            }
        }
        return '0'
    }
    const renderStatus = () => {
        const list = []
        for (let index = 0; index < props.list?.length; index++) {
            const className = ["flex center ds-w-100 default-status ds-p-12"]
            const el = props.list[index];
            if (el.key === props.selected) {
                className.push('selected-status')
            }
            list.push(<div
                onClick={() => props.onClickType(el.key)}
                className={className.join(' ')}
            >
                <Text type={props.statuType} className="text-end">{el.text}
                    {props.isCountable && <Text>{'(' + activeItemCount(el.key) + ')'}</Text>}
                </Text>
            </div>)
        }
        return list
    }
    return (
        renderStatus()
    )
}

export default StatusList

StatusList.defaultProps = {
    selected: null,
    statuType: null,
    onClickType: () => console.log(),
    className: null,
    counts: null,
    isCountable: false

};
StatusList.propTypes = {
    statut: PropTypes.string,
    selected: PropTypes.number,
    counts: PropTypes.number,
    statuType: PropTypes.string,
    className: PropTypes.string,
    onClickType: PropTypes.func,
    isCountable: PropTypes.bool,
};