import PropTypes from "prop-types";
import React from "react";
import { Icon, Input, Text } from "../../Atoms";

const MoleculeInput = (props) => {
  const containerClassName = ["ds-w-100"]
  if (props.containerClassName) {
    containerClassName.push(props.containerClassName)
  }
  return (
    <div className={containerClassName.join(' ')}>
      <div className="ds-ml-15 ds-mb-6 ds-w-100">
        <Text type="h3">{props.label}</Text>
      </div>
      <div className={`ds-w-100 relative`}>
        <Input
          value={props.value}
          className={props.className}
          onChange={props.onChange}
          disabled={props.disabled}
          errormsg={props.errormsg}
          label={props.label}
          type={props.type}
          id={props.id}
          isInvalid={props.isInvalid}
          isPassword={props.isPassword}
          onBlur={props.onBlur}
        />
        {props.icon && <Icon icon={props.icon} className={props.iconClassName} size={props.iconSize} onClick={props.onClickIcon} />}
      </div>
      {props.isInvalid && props.errormsg && <Text type={props.errormsgType} className="ds-ml-10 ds-mt-6">{props.errormsg}</Text>}
    </div>
  );
};

MoleculeInput.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  invalidclassName: PropTypes.string,
  onChange: PropTypes.func,
  onClickIcon: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  errormsg: PropTypes.string,
  errormsgType: PropTypes.string,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  iconSize: PropTypes.any,
  id: PropTypes.string,
  isPassword: PropTypes.bool,
};
MoleculeInput.defaultProps = {
  containerClassName: null,
  value: null,
  className: "default-input",
  isInvalid: false,
  onChange: (e) => { },
  onClickIcon: () => { },
  placeholder: null,
  invalidclassName: "invalid-default-input",
  errormsg: null,
  id: null,
  errormsgType: "type-error",
  disabled: false,
  label: null,
  type: "text",
  icon: null,
  iconClassName: "icon-input absolute",
  iconSize: 30,
  isPassword: false,
};

export default MoleculeInput;
