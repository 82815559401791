import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Text } from '../../Atoms'
import { render2Cercles } from '../../common'
import { imageUser } from '../../Config/Config'
import { ButtonWithIcon, Header } from '../../Molecules'
import { Contact } from '../../Organisme'
import { scrollToTop } from '../../Redux/authActions/authActions'

const TemplateMenu = (props) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(scrollToTop())
}, [])
  let headerProps = {}
  if (!props.seeContacts) {
    headerProps = {
      menu: "menu-burger-1",
      type: "type1",
      onClickMenu: props.onClickCloseMenu,
      clickedMenu: true,
    }
  } else {
    headerProps = {
      type: "type2",
      textType: "type-7",
      text: "My contacts",
      edit: false,
      share: false,
      onClickBack: props.onClickBackToMenu,
    }
  }
  return (
    <div className='ds-px-20 ds-w-100 ds-pb-20 ds-h-100 relative flex-col'>
      {render2Cercles()}
      <Header
        {...headerProps}
      />
      {!props.seeContacts ?
        <>

          <div className="ds-w-100 flex-col flex-gr-1 center">
            <Text type={"type-0"} className='ds-mb-38 pointer' onClick={() => props.onClickProfile()}>My profile</Text>
            <Text type={"type-0"} className='ds-mb-38 pointer' onClick={() => props.onClickMyEvents()}>My events</Text>
            <Text type={"type-0"} className='ds-mb-38 pointer' onClick={() => props.onClickSeeMyContacts()}>My contacts</Text>
          </div>
          <ButtonWithIcon
            text={"SIGN OUT"}
            textType={"default-text"}
            onClick={props.onLogOut}
            className={"type-2 pointer"}
          />
        </>
        :
        <div className="overflow-y ds-w-100 flex-gr-1" id="style-1">
          <div className='center ds-w-100'>
            {props.contactsList?.map((el, i) => {
              return (
                <Contact
                  key={i}
                  name={el.firstname.slice(0, 1) + "" + el.lastname.slice(0, 1)}
                  avatar={el.imageName !== null ? imageUser + el.imageName : ""}
                  pseudo={el.firstname + " " + el.lastname}
                  onClickAdd={() => props.onDeleteContact(i)}
                  deleteContact
                />
              )
            })}
          </div>
        </div>
      }

    </div >

  )
}

export default TemplateMenu