import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export default function PrivateRoute(props) {
  const [count, setCount] = useState(0)
  const token = useSelector(state => state.auth.token);
  if (!token && count === 0) {
    setTimeout(() => {
      setCount(count + 1)
    }, 1000);
  }
  if (token || (!token && count === 0)) {
    return (
      <div className='ds-w-100 ds-h-100'>
        {props.component}
      </div>
    );
  }
  return <Navigate to='/' replace />;
}
