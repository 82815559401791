import LOGO from "../Assets/Images/LOGO.svg";
import REEPLY from '../Assets/Images/REEPLY.svg'
import VISITRLOGO from "../Assets/Images/VISITRLOGO.svg";
import EVENT from "../Assets/Images/EVENT.svg";
import UNION from "../Assets/Images/Union.svg";
import MAP from '../Assets/Images/MAP.svg';
import moment from "moment";
import Store from "../Redux/Store";
import { setCopyLink } from "../Redux/EventActions/EventActions";
import copy from 'copy-text-to-clipboard';

const onSortEvent = (Events, asc=true) => {
    const sortedEvent= Events.sort((a, b) => {
      let dateA = new Date(a.start_date);
      let dateB = new Date(b.start_date);
      if(asc === true){
        return dateA - dateB;
      } else{
        return dateB - dateA
      }
    });
    return sortedEvent;
  };

const onFormatingDate = (date) => {
    return moment(date).format('MMMM DD - hh:mm a')
}

const onGetTime = (date) => {
    return moment(date).format('hh:mm a')
}

const onGetMonth = (date) => {
    return moment(date).format('MMMM')
}

const onGetDay = (date) => {
    return moment(date).format('DD')
}
function isLater(dateString1, dateString2) {
    return dateString1 > dateString2
}

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
const render3Cercles = () => {
    return <>
        <div className="cercle-absolute cercle-back-gr absolute"></div>
        <div className="cercle-absolute cercle-back-g absolute ds-wp-65 ds-hp-65"></div>
        <div className="cercle-absolute cercle-back-gra absolute"></div>
    </>
}
const render2Cercles = () => {
    return <>
        {renderCercleType2()}
        <div className="cercle-greay absolute z-index-3"></div>
    </>
}
const renderCercle = () => {
    return <div className="cercle-purpule absolute z-index-3"></div>
}
const renderCercleType2 = () => {
    return <div className="cercle-purpule-2 absolute z-index-3"></div>
}
const defineState = (value) => {
    return {
        value: value,
        isInvalid: false,
        isValid: false,
        errormsg: null,
    }
}
const getComponentProps = (el) => {
    if (el) {
        return {
            value: el?.value,
            isInvalid: el?.isInvalid,
            isValid: el?.isValid,
            errormsg: el?.errormsg,
        }
    }
    return null
}
const share = ({ id, description }) => {
    if (window?.navigator?.share !== undefined) {
        window.navigator.share({
            text: description,
            url: "https://reeply.io/eventDetails/" + id,
            title: description
        })
    } else {
        copy("https://reeply.io/eventDetails/" + id)
        Store.dispatch(setCopyLink(true));
    }
}
const getShareText = () => {
    if (window?.navigator?.share !== undefined) {
        return "INVITE VIA..."
    }
    return "COPY LINK..."
}

const formatDescription = (description) => {
    let value = description ? description : ""
    if (description) {
        while (value.indexOf(`\n`) !== -1) {
            value = value.replace(/[\n\r]/g, ' ')
        }
        while (value.indexOf(`  `) !== -1) {
            value = value.replace('  ', ' ')
        }
    }
    return value
}

export {
    getBase64,
    render3Cercles,
    LOGO,
    REEPLY,
    VISITRLOGO,
    EVENT,
    UNION,
    MAP,
    defineState,
    getComponentProps,
    renderCercle,
    render2Cercles,
    onFormatingDate,
    onGetTime,
    onGetMonth,
    onGetDay,
    isLater,
    share,
    getShareText,
    renderCercleType2,
    formatDescription,
    onSortEvent
}