export const resetEventId = () => {
    return {
        type: 'RESET_EVENT_ID',
    };
};
export const setEventId = (data) => {
    return {
        type: 'SET_EVENT_ID',
        value: data
    };
};
export const setEvent = (data) => {
    return {
        type: "SET_EVENT",
        value: data
    }
}
export const resetEvent = () => {
    return {
        type: "RESET_EVENT",
    }
}
export const deleteEvent = (index) => {
    return {
        type: "DELETE_EVENT",
        value: index
    }
}
export const deleteEventToReplay = (index) => {
    return {
        type: "DELETE_EVENT_TO_REPLAY",
        value: index
    }
}
export const setEventToReply = (data) => {
    return {
        type: "SET_EVENT_TO_REPLY",
        value: data
    }
}
export const removeLastEventToReply = (data) => {
    return {
        type: "REMOVE_LAST_EVENT_TO_REPLY",
    }
}
export const setCopyLink = (data) => {
    return {
        type:"SET_COPY_LINk",
        value:data
    }
}