import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from "react-click-away-listener";
import { Icon } from '../../Atoms';
import DatePicker from "react-datepicker";
import moment from "moment"
const AtomDatePicker = (props) => {
    const ref = useRef()
    const onFocus = () => {
        ref?.current?.setOpen(true)
        setTimeout(() => {
            onFocusInput()
        }, 200)
    }
    const datePickerClassName = ["ds-w-100", 'invisible-date-picker', 'ds-h-100', "absolute"]
    const containerClassName = ['ds-w-100 ds-hp-66 relative']
    const renderValue = () => {
        const className = ["ds-w-100", "visible-date-picker", "ds-hp-66", "pointer", "flex", "align-center", "ds-pl-52 ds-pr-8"]
        let value = props.placeholder
        if (props.isInvalid) { className.push('visible-date-picker-invalid') }
        if (props.value) {
            value = moment(new Date(props.value)).format('MMMM DD YYYY hh:mma')
            className.push('value')
        }
        return <div className={containerClassName.join(' ')} onClick={onFocus}>
            <div className="flex center absolute ds-wp-58 ds-h-100 z-index-2" style={{ left: 0 }}>
                <Icon
                    icon={"Calendar"}
                    size={"30px"}
                />
            </div>
            <div className={className.join(' ')}>{value}</div>
        </div>
    }
    const onChange = (e) => {
        props.onChange && props.onChange(e)
    }
    const onFocusInput = () => {
        const list = document.getElementsByClassName('react-datepicker__time-box')
        if (list?.length) {
            const box = list[0]
            const children = box.firstChild.children
            for (let index = 0; index < children.length; index++) {
                const child = children[index];
                if (child.innerHTML === "12:00 PM") {
                    child.innerHTML = "0:00 PM"
                }
                if (child.innerHTML === "12:30 PM") {
                    child.innerHTML = "0:30 PM"
                }
                if (child.innerHTML === "12:00 AM") {
                    child.innerHTML = "0:00 AM"
                }
                if (child.innerHTML === "12:30 AM") {
                    child.innerHTML = "0:30 AM"
                }

            }
        }
    }
    return <ClickAwayListener onClickAway={true}>
        <div className="relative ds-w-100">
            {renderValue()}
            <DatePicker ref={ref}
                className={datePickerClassName.join(' ')}
                selected={props.value ? new Date(props.value) : null}
                onChange={onChange}
                showTimeSelect
                calendarClassName='ds-single-date-picker'
            />
        </div>
    </ClickAwayListener>
}
export default AtomDatePicker;
AtomDatePicker.propTypes = {
    selected: PropTypes.any,
    className: PropTypes.string,
    isInvalid: PropTypes.bool,
    refDate: PropTypes.string,
    onChange: PropTypes.func,
    onClickIcon: PropTypes.func,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    iconClassName: PropTypes.string,
    iconSize: PropTypes.any,

};
AtomDatePicker.defaultProps = {
    selected: new Date(),
    className: "default-input",
    isInvalid: false,
    onChange: () => console.log(),
    onClickIcon: () => console.log(),
    placeholder: null,
    refDate: null,
    icon: "Calendar",
    iconClassName: "icon-calendar",
    iconSize: 30,

};